import "./faq.css";

function FAQ({ faq }) {
  return (
    <div className="mobile-faqs-container">
      <div className="mobile-faqs-header">
        <div className="mobile-faqs-header-text">FAQs</div>
      </div>
      <div className="mobile-faqs-details">
        {/* <div className="mobile-faqs-details-container"> */}
        <div className="mobile-faq-question-container">{faq[0]["Q"]}</div>
        <div className="mobile-faq-answer-container">{faq[0]["A"]}</div>
        <div className="mobile-faq-question-container">{faq[1]["Q"]}</div>
        <div className="mobile-faq-answer-container">{faq[1]["A"]}</div>
        <div className="mobile-faq-question-container">{faq[2]["Q"]}</div>
        <div className="mobile-faq-answer-container">{faq[2]["A"]}</div>
        {/* </div> */}
        {/* <div className="mobile-faqs-details-container"> */}
        <div className="mobile-faq-question-container">{faq[3]["Q"]}</div>
        <div className="mobile-faq-answer-container">{faq[3]["A"]}</div>
        <div className="mobile-faq-question-container">{faq[4]["Q"]}</div>
        <div className="mobile-faq-answer-container">{faq[4]["A"]}</div>
        <div className="mobile-faq-question-container">{faq[5]["Q"]}</div>
        <div className="mobile-faq-answer-container">{faq[5]["A"]}</div>
        {/* </div> */}
      </div>
    </div>
  );
}

export default FAQ;
