import { useState, useEffect } from "react";
import { useAddress } from "@thirdweb-dev/react";

import "./step1.css";
import WizardAPI from "../../wizard-api";
import PriceDetailsForAllowlist from "../../../common-components/price-details-for-allowlist/price-details-for-allowlist";
import PriceDetails from "../../../common-components/price-details/price-details";
import PriceDetailsLimitedEdition from "../../../common-components/price-details-limited-edition/price-details-limited-edition";
import PriceDetailsLimitedEditionForAllowlist from "../../../common-components/price-details-limited-edition-for-allowlist/price-details-limited-edition-for-allowlist";
import StepLoader from "../../../common-components/step-loader/step-loader";

function PreLaunchStep1({ artistId, membershipId, stepChange, disableAllowlist }) {
  const [loading, setLoading] = useState(true);
  const [stepDetails, setStepDetails] = useState({});
  const [allowlisted, setAllowlisted] = useState(false);
  const address = useAddress();

  const fetchStepDetails = async () => {
    const stepDetailsRes = await WizardAPI.getStepDetails(artistId, membershipId, "pre_launch", 1);
    // console.log("pre-launch.js/step1.js step details>>>>>>", stepDetailsRes);
    if (stepDetailsRes["success"] && stepDetailsRes["code"] === 200) {
      setStepDetails(stepDetailsRes["data"]);
      setLoading(false);
    } else {
      alert("Something went wrong. Please refresh page.");
    }
  };

  useEffect(() => {
    fetchStepDetails();
  }, []);

  useEffect(() => {
    if (address) {
      WizardAPI.getMemberDetails(artistId, membershipId, address, "").then((response) => {
        if (response && response.data && response.data.on_allowlist === true) setAllowlisted(true);
      });
    } else {
      setAllowlisted(false);
    }
  }, [address]);

  return (
    <>
      {loading ? (
        <StepLoader />
      ) : (
        <div className="pre-launch-step1-container">
          <div className="pre-launch-step1-membership-desc-container">
            <div className="pre-launch-step1-membership-main-desc-container">
              <img
                className="pre-launch-step1-membership-main-desc"
                src={stepDetails["membership-description"]}
                alt="desc"
              />
            </div>
            <div className="pre-launch-step1-membership-sub-desc-container">
              <div className="pre-launch-step1-membership-sub-desc">{stepDetails["membership-sub-description"]}</div>
            </div>
          </div>
          {allowlisted === true ? (
            stepDetails["limited-edition-raffle"] ? (
              <PriceDetailsLimitedEditionForAllowlist data={stepDetails} launch={false} />
            ) : (
              <PriceDetailsForAllowlist data={stepDetails} launch={false} />
            )
          ) : stepDetails["limited-edition-raffle"] ? (
            <PriceDetailsLimitedEdition data={stepDetails} launch={false} />
          ) : (
            <PriceDetails data={stepDetails} launch={false} />
          )}
          {disableAllowlist ? (
            ""
          ) : (
            <div className="pre-launch-step1-navigation-container">
              <div className="pre-launch-step1-allowlist-text-container">
                <div className="pre-launch-step1-allowlist-text">{stepDetails["allowlist-text"]}</div>
              </div>
              <div className="pre-launch-step1-button-container">
                <div className="pre-launch-step1-button" onClick={() => stepChange(2)}>
                  <div className="pre-launch-step1-button-text">{stepDetails["button-cta"]}</div>
                </div>
              </div>
              <div className="pre-launch-step1-thirdweb-container">
                <img
                  className="pre-launch-step1-thirdweb-logo"
                  src={require("../../../../assets/thirdweb-logo.webp")}
                  alt="thirdweb-logo"
                />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default PreLaunchStep1;
