import "./about-program.css";

function AboutProgram({ aboutArt }) {
  return (
    <div className="mobile-launch-info-container">
      <div className="mobile-launch-info-details">
        <div className="mobile-launch-info-details1-container">
          <div className="mobile-launch-info-details-message1-text">{aboutArt[0]}</div>
        </div>

        <div className="mobile-launch-info-details2-container">
          <div className="mobile-launch-info-details-message2-text">{aboutArt[1]}</div>
        </div>
        <div className="mobile-launch-info-details3-container">
          <div className="mobile-launch-info-details-message3-text">{aboutArt[2]}</div>
        </div>
      </div>
    </div>
  );
}

export default AboutProgram;
