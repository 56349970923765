import "./tasseo-cup-loader.css";

export function TasseoCupLoader() {
  return (
    <div className="tasseo-cup-loader-container">
        <img className="loader-icon " src={require("./../../../assets/website-loader.gif")} alt="loader" />
    </div>
  );
}

// export TasseoCupLoader;