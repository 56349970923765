import "./thirdweb-logo.css";

function ThirdWebLogo({ width }) {
  return (
    <div style={{ width: width, height: "16px" }}>
      <img
        className="mobile-thirdweb-logo"
        src={require("../../../../assets/thirdweb-logo.webp")}
        alt="thirdweb-logo"
      />
    </div>
  );
}

export default ThirdWebLogo;
