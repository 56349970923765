import { useState, useEffect } from "react";

import "./post-launch-nft-container.css";
import PostLaunchNftMediaContainer from "../post-launch-nft-media-container/post-launch-nft-media-container";
function PostLaunchNftContainer({ userNfts, width, passCount }) {
  useEffect(() => {
    if (userNfts.length) {
      // console.log("post-launch-nft-container.js >>>>>>>", userNfts);
      let el = document.getElementById("minting-live");
      let sc = document.getElementById("scroller");
      if (el) {
        // if minting/auction is live then scroll to that nft
        let h = el.offsetLeft;
        sc.scroll(h - 140, 0);
      } else {
        // if nothing is live then scroll to last item in the list
        sc.scroll(sc.offsetWidth + 140, 0);
      }
    }
  }, [userNfts]);

  return (
    <div className="post-launch-nft-container-scroller" id="scroller" style={{ width: width }}>
      {userNfts.map((nft, index) => (
        <PostLaunchNftMediaContainer
          nftData={nft}
          index={index}
          key={`${nft["drop_number"]}key`}
          passCount={passCount}
        />
      ))}
    </div>
  );
}

export default PostLaunchNftContainer;
