import { useState, useEffect } from "react";
import WizardAPI from "../../wizard-api";
import StepLoader from "../../../common-components/step-loader/step-loader";

import "./step2.css";

function PreLaunchStep2({ artistId, membershipId, stepChange, disableAllowlist }) {
  const [loading, setLoading] = useState(true);
  const [stepDetails, setStepDetails] = useState({});

  const fetchStepDetails = async () => {
    const stepDetailsRes = await WizardAPI.getStepDetails(artistId, membershipId, "pre_launch", 2);
    // console.log("pre-launch.js/step2.js step details>>>>>>", stepDetailsRes["data"]);
    if (stepDetailsRes["success"] && stepDetailsRes["code"] === 200) {
      setStepDetails(stepDetailsRes["data"]);
      setLoading(false);
    } else {
      alert("Something went wrong.");
      stepChange(1);
    }
  };
  useEffect(() => {
    if (disableAllowlist) {
      stepChange(1);
    }
    fetchStepDetails();
  }, []);
  return (
    <>
      {loading ? (
        <StepLoader />
      ) : (
        <div className="pre-launch-step2-container">
          <div className="pre-launch-step2-desc-container">
            <div className="pre-launch-step2-desc-heading">{stepDetails["heading"]}</div>
            <div className="pre-launch-step2-desc-sub-heading">{stepDetails["sub-heading1"]}</div>
            <div className="pre-launch-step2-desc-sub-heading">{stepDetails["sub-heading2"]}</div>
            <div className="pre-launch-step2-button-container">
              <div className="pre-launch-step2-button" onClick={() => stepChange(3)}>
                <div className="pre-launch-step2-button-text">{stepDetails["button-cta"]}</div>
              </div>
            </div>
            <div className="pre-launch-step2-thirdweb-container">
              <img
                className="pre-launch-step2-thirdweb-logo"
                src={require("../../../../assets/thirdweb-logo.webp")}
                alt="thirdweb-logo"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PreLaunchStep2;
