import "./testimonials.css";

function Testimonials({ testimonials }) {
  return (
    <div className="testimonials-main-container">
      <div className="testimonial-header-container">
        <div className="testimonial-header-text">WALL OF LOVE</div>
      </div>
      <div className="testimonials-detail-container">
        <div className="testimonial-container">
          <div className="testimonial-collector-details-container">
            <div className="testimonial-image-container">
              <img className="testimonial-image" src={testimonials[0].collector_image} alt="collector-1-profile-pic" />
            </div>
            <div className="testimonial-details-container">
              <div className="testimonial-name">{testimonials[0].collector_name}</div>
              <div
                className="testimonial-twitter"
                onClick={() => {
                  window.open(`https://twitter.com/${testimonials[0].collector_twitter_id}`);
                }}
              >
                @{testimonials[0].collector_twitter_id}
              </div>
            </div>
          </div>
          <div className="testimonial-text-container">
            <div className="testimonial-text">"{testimonials[0].testimonial}"</div>
          </div>
        </div>

        <div className="testimonial-container">
          <div className="testimonial-collector-details-container">
            <div className="testimonial-image-container">
              <img className="testimonial-image" src={testimonials[1].collector_image} alt="collector-2-profile-pic" />
            </div>
            <div className="testimonial-details-container">
              <div className="testimonial-name">{testimonials[1].collector_name}</div>
              <div
                className="testimonial-twitter"
                onClick={() => {
                  window.open(`https://twitter.com/${testimonials[1].collector_twitter_id}`);
                }}
              >
                @{testimonials[1].collector_twitter_id}
              </div>
            </div>
          </div>
          <div className="testimonial-text-container">
            <div className="testimonial-text">"{testimonials[1].testimonial}"</div>
          </div>
        </div>

        <div className="testimonial-container">
          <div className="testimonial-collector-details-container">
            <div className="testimonial-image-container">
              <img className="testimonial-image" src={testimonials[2].collector_image} alt="collector-3-profile-pic" />
            </div>
            <div className="testimonial-details-container">
              <div className="testimonial-name">{testimonials[2].collector_name}</div>
              <div
                className="testimonial-twitter"
                onClick={() => {
                  window.open(`https://twitter.com/${testimonials[2].collector_twitter_id}`);
                }}
              >
                @{testimonials[2].collector_twitter_id}
              </div>
            </div>
          </div>
          <div className="testimonial-text-container">
            <div className="testimonial-text">"{testimonials[2].testimonial}"</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
