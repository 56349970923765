import "./step-loader.css";

function StepLoader({ width = "97vw" }) {
  return (
    <div className="mobile-step-loader-container" style={{ width: width }}>
      <img className="mobile-step-loader-image" src={require("../../../../assets/step-loader.png")} alt="loader" />
    </div>
  );
}

export default StepLoader;
