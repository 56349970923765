import "./event-date.css";
import { useEffect, useState } from "react";
import * as React from "react";

function EventDate({ data, mint }) {
  const [mintDate, setMintDate] = useState({});

  useEffect(() => {
    if (!data || !data["mint-start-time"] || !data["mint-end-time"]) {
      return;
    }
    let d = new Date(data["mint-start-time"]);
    // if (launch) {
    //   d = new Date(data["mint-end-time"]);
    // }
    let time = new Intl.DateTimeFormat("default", {
      hour12: true,
      hour: "numeric",
      minute: "numeric",
    }).format(d);
    time = time.toUpperCase();

    let month = new Intl.DateTimeFormat("default", {
      month: "short",
    }).format(d);
    month = month.toUpperCase();

    let weekday = new Intl.DateTimeFormat("default", {
      weekday: "short",
    }).format(d);
    weekday = weekday.toUpperCase();

    setMintDate({
      time: time,
      month: month,
      weekday: weekday,
      date: d.getDate(),
      year: d.getFullYear(),
    });
  }, []);
  return (
    <>
      <div className="mobile-event-date-container">
        <div
          className="mobile-event-date-month-container"
          style={{
            backgroundColor: mint ? "rgba(45, 183, 206, 0.05)" : "rgba(222, 105, 161, 0.05)",
            color: mint ? "#2db7ce" : "#DE69A1",
          }}
        >
          <div className="mobile-event-date-month">{mintDate.month}</div>
        </div>
        <div
          className="mobile-event-date-day-container"
          style={{
            color: mint ? "#2db7ce" : "#DE69A1",
            backgroundColor: mint ? "rgba(45, 183, 206, 0.05)" : "rgba(222, 105, 161, 0.05)",
          }}
        >
          <div className="mobile-event-date-day">{mintDate.date}</div>
        </div>
        <div
          className="mobile-event-date-weekday-container"
          style={{
            color: mint ? "#2db7ce" : "#DE69A1",
            backgroundColor: mint ? "rgba(45, 183, 206, 0.1)" : "rgba(222, 105, 161, 0.1)",
          }}
        >
          <div className="mobile-event-date-weekday">{mintDate.weekday}</div>
        </div>
      </div>
    </>
  );
}

export default EventDate;
