import { useState, useEffect } from "react";

import "./program-schedule.css";

function ProgramSchedule({ programScheduleData }) {
  const [programSchedule, setProgramSchedule] = useState([]);

  useEffect(() => {
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    var currentDateAndTime = new Date();
    for (let i = 0; i < programScheduleData.length; i++) {
      let eventStartDateAndTime = new Date(programScheduleData[i]["event-start-date"]);
      let eventEndDateAndTime = new Date(programScheduleData[i]["event-end-date"]);

      let eventStartTime = eventStartDateAndTime.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      let eventEndTime = eventEndDateAndTime.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });

      let eventDuration = Math.abs(eventEndDateAndTime - eventStartDateAndTime) / 36e5;
      let eventStartMonth = month[eventStartDateAndTime.getMonth()].slice(0, 3);
      let eventEndMonth = month[eventEndDateAndTime.getMonth()].slice(0, 3);

      let eventStartWeekDay = weekday[eventStartDateAndTime.getDay()].slice(0, 3);
      let eventEndWeekDay = weekday[eventEndDateAndTime.getDay()].slice(0, 3);

      let eventStartDate = eventStartDateAndTime.getDate();
      let eventEndDate = eventEndDateAndTime.getDate();

      let eventStarts = eventStartWeekDay + ", " + eventStartMonth + " " + eventStartDate + " at " + eventStartTime;
      let eventEnds = eventEndWeekDay + ", " + eventEndMonth + " " + eventEndDate + " at " + eventEndTime;

      programScheduleData[i] = {
        ...programScheduleData[i],
        "event-start-date-numeric": eventStartDate < 10 ? "0" + eventStartDate : eventStartDate,
        "event-end-date-numeric": eventEndDate < 10 ? "0" + eventEndDate : eventEndDate,
        "event-duration": eventDuration + " HRS",
        "event-start-month": eventStartMonth,
        "event-end-month": eventEndMonth,
        "event-start-time": eventStartTime,
        "event-end-time": eventEndTime,
        "event-live":
          eventStartDateAndTime <= currentDateAndTime && currentDateAndTime < eventEndDateAndTime ? true : false,
        "event-starts": eventStarts,
        "event-ends": eventEnds,
        "event-start-weekday": eventStartWeekDay,
        "event-end-weekday": eventEndWeekDay,
      };
    }
    setProgramSchedule(programScheduleData);
  }, [programSchedule]);

  return (
    <div id="mobile-program-schedule" className="mobile-program-schedule-container">
      <div className="mobile-program-schedule-header">
        <div className="mobile-program-schedule-header-text">SCHEDULE</div>
      </div>
      {programSchedule.map((item, index) => {
        return (
          <div className="mobile-program-schedule-data-container" key={index}>
            <div
              className="mobile-program-schedule-title-and-image-container"
              style={{
                backgroundColor:
                  index == 0 ? "#FEF2F5" : !item["event-title"].includes("Auction") ? "#F4FFF2" : "#F7F6FF",
              }}
            >
              <img
                className="mobile-program-schedule-image "
                src={
                  index == 0
                    ? require("../../../../assets/mobile-pass.webp")
                    : index == programSchedule.length - 1
                    ? require("../../../../assets/mobile-poap-mint.webp")
                    : !item["event-title"].includes("Auction")
                    ? require("../../../../assets/mobile-mint.webp")
                    : require("../../../../assets/mobile-auction.webp")
                }
                alt="cross"
              />
              <div className="mobile-program-schedule-title-text">
                {item["event-title"].replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())}
              </div>
            </div>
            <div className="mobile-program-schedule-start-end-date-container">
              <div className="mobile-program-schedule-date-container">
                <div className="mobile-program-schedule-date-text-container">
                  <div className="mobile-program-schedule-date-text">Starts</div>
                </div>
                <div className="mobile-program-schedule-day-time-container">
                  <div className="mobile-program-schedule-day-time-text">
                    {item["event-start-time"]}, {item["event-start-weekday"]}
                  </div>
                </div>
                <div className="mobile-program-schedule-month-container">
                  <div className="mobile-program-schedule-month-text">
                    {item["event-start-month"]} {item["event-start-date-numeric"]}
                  </div>
                </div>
              </div>

              <div className="mobile-program-schedule-date-container">
                <div className="mobile-program-schedule-date-text-container">
                  <div className="mobile-program-schedule-date-text">Ends</div>
                </div>
                <div className="mobile-program-schedule-day-time-container">
                  <div className="mobile-program-schedule-day-time-text">
                    {item["event-end-time"]}, {item["event-end-weekday"]}
                  </div>
                </div>
                <div className="mobile-program-schedule-month-container">
                  <div className="mobile-program-schedule-month-text">
                    {" "}
                    {item["event-end-month"]} {item["event-end-date-numeric"]}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ProgramSchedule;
