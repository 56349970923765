import { useEffect, useState } from "react";
import * as React from "react";

function MembershipDescTicketPartTwoForAllowlist({ data, launch }) {
  const [mintDate, setMintDate] = useState({});

  useEffect(() => {
    if (!data || !data["mint-start-time"] || !data["mint-end-time"]) {
      return;
    }
    let d = new Date(data["mint-start-time"]);
    if (launch) {
      d = new Date(data["mint-end-time"]);
    }
    let time = new Intl.DateTimeFormat("default", {
      hour12: true,
      hour: "numeric",
      minute: "numeric",
    }).format(d);
    time = time.toLowerCase();

    let month = new Intl.DateTimeFormat("default", {
      month: "short",
    }).format(d);
    month = month.toUpperCase();

    let weekday = new Intl.DateTimeFormat("default", {
      weekday: "short",
    }).format(d);
    weekday = weekday.toUpperCase();

    setMintDate({
      time: time,
      month: month,
      weekday: weekday,
      date: d.getDate(),
      year: d.getFullYear(),
    });
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "80vw",
        height: "calc(80vw * 0.23)",
        maxWidth: "400px",
        // border: "2px solid red",
      }}
    >
      <svg width="199" height="94" viewBox="0 0 199 94" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="mticket">
          <path
            id="ticket"
            d="M0.999786 6.32366V0.942871L119.935 1.28199C119.834 2.41479 121.079 4.68038 124.45 4.68038C127.821 4.68038 128.566 2.25589 128.867 1.21749C201.812 1.21749 175.248 1.22551 184.88 1.22551C194.512 1.22551 197.946 7.8335 198.348 11.1375V64.9454C198.348 71.7422 191.199 74.377 187.788 74.6602L128.363 74.6306C128.363 72.9314 126.557 70.9491 123.848 70.9491C121.139 70.9491 119.333 73.2146 119.333 74.6306L0.999786 74.2915V69.1939C2.60504 69.1356 6.15269 68.0611 6.15269 63.53C6.15269 58.9988 2.94307 57.4061 0.999786 57.5828V52.4852C3.2072 52.3908 6.15269 50.5594 6.15269 46.2548C6.15269 41.9502 3.2072 40.6852 0.999786 40.3076V35.21C3.2072 35.1156 6.18816 33.4904 6.18816 29.4123C6.18816 25.3343 2.90619 23.9764 0.999786 23.5988V17.9348C2.61136 17.2348 6.15269 16.802 6.15269 12.2708C6.15269 7.73966 2.90619 6.51246 0.999786 6.32366Z"
            fill="white"
            stroke="#E0DDEA"
            strokeWidth="0.811135"
          />
          <g id="Frame 1395">
            <rect x="132.053" y="10" width="57" height="56" rx="3.24454" fill="#2DB7CE" fillOpacity="0.1" />
            <path
              id="Rectangle 2716"
              d="M132.053 13.3144C132.053 11.5225 133.505 10.0698 135.297 10.0698H185.424C187.216 10.0698 188.669 11.5225 188.669 13.3144V22.5732H132.053V13.3144Z"
              fill="#2DB7CE"
              fillOpacity="0.1"
            />
            <g id="Rectangle 2719">
              <mask id="path-4-inside-1_3542_16043" fill="white">
                <path d="M132.127 34.77H188.74V53.5166H132.127V34.77Z" />
              </mask>
              <path d="M132.127 34.77H188.74V53.5166H132.127V34.77Z" fill="white" />
              <path
                d="M187.118 34.77V53.5166H190.363V34.77H187.118ZM133.749 53.5166V34.77H130.505V53.5166H133.749Z"
                fill="#2DB7CE"
                fillOpacity="0.1"
                mask="url(#path-4-inside-1_3542_16043)"
              />
            </g>
            <text
              id="22"
              fill="#2DB7CE"
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Open Sans"
              fontSize="18.2581"
              fontWeight="bold"
              letterSpacing="0em"
            >
              <tspan x="150.104" y="50.106">
                {mintDate.date}
              </tspan>
            </text>
            <text
              id="december"
              fill="#2DB7CE"
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Open Sans"
              fontSize="8"
              fontWeight="bold"
              letterSpacing="0.16em"
            >
              <tspan x="151.506" y="30.7998">
                {mintDate.month}
              </tspan>
            </text>
            <text
              id="9:30 am"
              fill="#2DB7CE"
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Open Sans"
              fontSize="8"
              fontWeight="600"
              letterSpacing="0.08em"
            >
              <tspan x="143.492" y="19.7598">
                {mintDate.time}
              </tspan>
            </text>
            <text
              id="2023"
              fill="#2DB7CE"
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Open Sans"
              fontSize="8"
              fontWeight="800"
              letterSpacing="0.48em"
            >
              <tspan x="145.418" y="62.8662">
                {mintDate.year}
              </tspan>
            </text>
          </g>
          <g id="Group 9485">
            <text
              id="0.06"
              fill="#131313"
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Open Sans"
              fontSize="18.5874"
              fontWeight="bold"
              letterSpacing="0em"
            >
              <tspan x="27.2695" y="46.5834">
                {data.price}
              </tspan>
            </text>
            <text
              id="ETH"
              fill="#131313"
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Open Sans"
              fontSize="9.68821"
              fontWeight="bold"
              letterSpacing="0em"
            >
              <tspan x="85.0957" y="46.0656">
                ETH
              </tspan>
            </text>
            <g id="reMember Pass">
              <text
                fill="#131313"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Open Sans"
                fontSize="8.81146"
                fontWeight="600"
                letterSpacing="0em"
              >
                <tspan x="48.8432" y="21.1176">
                  {data["price-cta"]}
                </tspan>
              </text>
            </g>
            <path id="Vector 51" d="M12.6133 24.6123L112.32 24.6123" stroke="#E0DDEA" strokeWidth="0.811135" />
            <path id="Vector 56" d="M12.6133 53.9165L112.32 53.9165" stroke="#E0DDEA" strokeWidth="0.811135" />
            <path
              id="Rectangle 135"
              d="M12.7474 9.76934H111.914V66.4268H12.7474V9.76934Z"
              stroke="#E0DDEA"
              strokeWidth="0.811135"
            />
          </g>
          <path id="Vector 58" d="M24.2168 39.9844L76.6793 39.9844" stroke="#2DB7CE" strokeWidth="2.49873" />
          <text
            id="0.03 ETH"
            fill="#2DB7CE"
            xmlSpace="preserve"
            style={{ whiteSpace: "pre" }}
            fontFamily="Open Sans"
            fontSize="9.99493"
            fontWeight="bold"
            letterSpacing="0em"
          >
            <tspan x="42.0918" y="62.9858">
              {data["allowlist-price"]} ETH
            </tspan>
          </text>
        </g>
      </svg>
    </div>
  );
}

export default MembershipDescTicketPartTwoForAllowlist;
