import "./step-loader.css";

function StepLoader({ width = "58.8vw" }) {
  return (
    <div className="step-loader-container" style={{ width: width }}>
      <img className="step-loader-image" src={require("../../../assets/step-loader.png")} alt="loader" />
    </div>
  );
}

export default StepLoader;
