import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";

import "./App.css";
import PhaseDetailsAPI from "./api/phase-details-api";
import Header from "./components/common-components/Header/header";
import PreLaunch from "./components/Wizard/Pre-launch/pre-launch";
import Launch from "./components/Wizard/Launch/launch";
import StepLoader from "../src/components/common-components/step-loader/step-loader";
import SecondFold from "./components/common-components/second-fold/second-fold";
import PostLaunch from "./components/Wizard/Post-launch/post-launch";

function App() {
  const [loading, setLoading] = useState(true);
  const [artistDetails, setArtistDetails] = useState({});
  const [phaseDetails, setPhaseDetails] = useState({});
  const [membershipDetails, setMembershipDetails] = useState({});
  const [nftDetails, setNftDetails] = useState({});
  const [artistId, setArtistId] = useState(-1);
  const [membershipId, setMembershipId] = useState(-1);
  const [preLaunchPhase, setPreLaunchPhase] = useState(false);
  const [launchPhase, setLaunchPhase] = useState(false);
  const [postLaunchPhase, setPostLaunchPhase] = useState(false);
  const [disableAllowlist, setDisableAllowlist] = useState(false);
  const [currentPhase, setCurrentPhase] = useState("");
  const [passLimit, setPassLimit] = useState(4);
  const [programAttributes, setProgramAttributes] = useState({});

  const params = useParams();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  let phase = searchParams.get("phase");
  let step = searchParams.get("step");
  let inviteCode = searchParams.get("invite_code");
  // console.log("App.js searchParams>>>>>>>", phase, step, inviteCode);

  const convertToLocalTimestamp = (timestamp) => {
    var preLaunchStartTime = timestamp + "Z";
    var datetime = new Date(preLaunchStartTime);
    return datetime;
  };

  const getNavigationUrlParams = (phase, step) => {
    let urlParams = "";
    if (inviteCode) {
      urlParams = `?invite_code=${inviteCode}` + "&phase=" + String(phase) + "&step=" + String(step);
    } else {
      urlParams = "?phase=" + String(phase) + "&step=" + String(step);
    }

    return urlParams;
  };

  const findCurrentPhase = (phaseDetails, nftDetails) => {
    const preLaunchStartTime = convertToLocalTimestamp(phaseDetails.pre_launch_start_date);
    const preLaunchEndTime = convertToLocalTimestamp(phaseDetails.pre_launch_end_date);
    const launchStartTime = convertToLocalTimestamp(phaseDetails.launch_start_date);
    const launchEndTime = convertToLocalTimestamp(phaseDetails.launch_end_date);
    const postLaunchStartTime = convertToLocalTimestamp(phaseDetails.post_launch_start_date);
    const postLaunchEndTime = convertToLocalTimestamp(phaseDetails.post_launch_end_date);
    var now = new Date();
    var currentDateAndTime = now;
    // console.log("App.js pre launch start time>>", preLaunchStartTime);
    // console.log("App.js pre launch end time>>", preLaunchEndTime);
    // console.log("App.js launch start time>>", launchStartTime);
    // console.log("App.js launch end time>>", launchEndTime);
    // console.log("App.js post launch start date>>>", postLaunchStartTime);
    // console.log("App.js post launch end date>>>", postLaunchEndTime);
    // console.log("App.js current time>>>>>>", currentDateAndTime);

    let phaseName = "";

    let phaseSteps = {
      pre_launch: ["1", "2", "3", "3.1", "4", "5"],
      launch: ["1", "2", "3", "4.1", "4.2"],
      post_launch: ["1", "2", "3", "4.1", "4.2"],
    };

    // finding the current phase
    if (
      // check if current phase is Pre Launch
      preLaunchStartTime <= currentDateAndTime &&
      currentDateAndTime <= preLaunchEndTime
    ) {
      setNftDetails(nftDetails[0]);
      phaseName = "pre_launch";
      setPreLaunchPhase(true);
    } else if (
      // check if current phase is After Pre Launch, navigation button is disabled
      preLaunchEndTime < currentDateAndTime &&
      currentDateAndTime < launchStartTime
    ) {
      setNftDetails(nftDetails[0]);
      phaseName = "pre_launch";
      setPreLaunchPhase(true);
      setDisableAllowlist(true);
    } else if (
      // check if current phase is Launch
      launchStartTime <= currentDateAndTime &&
      currentDateAndTime <= launchEndTime
    ) {
      setNftDetails(nftDetails);
      phaseName = "launch";
      setLaunchPhase(true);
    } else {
      setNftDetails(nftDetails);
      phaseName = "post_launch";
      setPostLaunchPhase(true);
    }

    // the step is not present in the given phase then change step to 1
    if (!phaseSteps[phaseName].includes(step)) {
      step = 1;
    }

    // navigate to set phase and step
    navigate({
      pathname: "/" + String(params.membershipSlug),
      search: getNavigationUrlParams(phaseName, step),
    });
  };

  const fetchCurrentPhaseDetails = async () => {
    let artistId = -1;
    let membershipId = -1;
    let artistSlug = window.location.host.split(".")[0];
    let membershipSlug = params.membershipSlug;
    // console.log("App.js artist and membership slug>>>>>>>>", artistSlug, membershipSlug);

    // artistSlug = "nightly";
    const artistDetailsRes = await PhaseDetailsAPI.getArtistDetails(artistSlug);
    // console.log("App.js artist details>>>>>>>>>", artistDetailsRes);
    if (artistDetailsRes["success"] && artistDetailsRes["code"] === 200) {
      artistId = artistDetailsRes["data"]["artist_id"];
      setArtistId(artistDetailsRes["data"]["artist_id"]);
      setArtistDetails(artistDetailsRes["data"]);

      const membershipDetailsRes = await PhaseDetailsAPI.getMemberShipDetails(membershipSlug);
      if (membershipDetailsRes["success"] && membershipDetailsRes["code"] === 200) {
        // console.log("App.js membership details>>>>>>>>>>", membershipDetailsRes["data"]["membership_details"]);
        setMembershipId(membershipDetailsRes["data"]["membership_details"]["membership_id"]);
        setMembershipDetails(membershipDetailsRes["data"]["membership_details"]);
        setPassLimit(membershipDetailsRes["data"]["membership_details"]["edition_limit"]);
        setProgramAttributes(membershipDetailsRes["data"]["membership_details"]["program_attributes"]);

        // if it is the short program and program is not successful then disable the button on the main page
        const programData = membershipDetailsRes["data"]["membership_details"]["program_attributes"];
        if (programData && "mint-end-time" in programData) {
          const now = new Date();
          let mintEndTime = new Date(programData["mint-end-time"]);
          if (now > mintEndTime) {
            setDisableAllowlist(true);
          }
        }

        membershipId = membershipDetailsRes["data"]["membership_details"]["membership_id"];
        const phaseDetailsRes = await PhaseDetailsAPI.getPhaseDetails(artistId, membershipId);
        if (phaseDetailsRes["success"] && phaseDetailsRes["code"] === 200) {
          // console.log("App.js phase details>>>>>>>>", phaseDetailsRes);
          setPhaseDetails(phaseDetailsRes["data"]);
          findCurrentPhase(phaseDetailsRes["data"], membershipDetailsRes["data"]["drop_details"]);
          setLoading(false);
        } else {
          // alert("Something went wrong. Please refresh page.");
          // fetchCurrentPhaseDetails();
        }
      } else {
        // alert("Something went wrong. Please refresh page.");
        // fetchCurrentPhaseDetails();
      }
    } else {
      // alert("Something went wrong. Please refresh page.");
      // fetchCurrentPhaseDetails();
    }
  };

  useEffect(() => {
    fetchCurrentPhaseDetails();
  }, []);

  return (
    <>
      {loading ? (
        <div className="website-loader-container">
          <img className="website-loader " src={require("../src/assets/website-loader.gif")} alt="loader" />
        </div>
      ) : (
        <>
          <Header
            name={membershipDetails.name + " by " + artistDetails.name}
            desc={artistDetails.description}
            twitter={artistDetails.twitter}
            discord={artistDetails.discord}
            linktree={artistDetails.linktree}
            logo={artistDetails.logo}
            disableAllowlist={disableAllowlist}
          />
          {preLaunchPhase ? <PreLaunch nftDetails={nftDetails} disableAllowlist={disableAllowlist} /> : ""}
          {launchPhase ? (
            <Launch nftDetails={nftDetails} passLimit={passLimit} programAttributes={programAttributes} />
          ) : (
            ""
          )}
          {postLaunchPhase ? <PostLaunch nftDetails={nftDetails} programAttributes={programAttributes} /> : ""}
          <SecondFold
            timelineImage={membershipDetails["timeline_image"]}
            faq={membershipDetails["faq"]}
            howItWorks={membershipDetails["how_it_works"]}
            testimonials={membershipDetails["testimonials"]}
            aboutArt={membershipDetails["about_art"]}
          />
        </>
      )}
    </>
  );
}

export default App;
