import "./second-fold.css";

import Testimonials from "../Testimonials/testimonials";
import LaunchInfo from "../launch-info/launch-info";
import HowItWorks from "../How-It-Works/how-it-works";
import ProgramSchedule from "../mint-timeline-svg/program-schedule";
import FAQ from "../FAQ/faq";
import Footer from "../footer/footer";

function SecondFold({ timelineImage, faq, howItWorks, testimonials, aboutArt }) {
  return (
    <div className="second-fold-container">
      {testimonials && testimonials.length == 3 ? (
        <div className="second-fold-testimonial-container">
          <Testimonials testimonials={testimonials} />
        </div>
      ) : (
        ""
      )}
      {aboutArt && aboutArt.length == 3 ? <LaunchInfo aboutArt={aboutArt} /> : ""}
      {howItWorks && howItWorks.length == 4 ? <HowItWorks howItWorks={howItWorks} /> : ""}
      <ProgramSchedule programScheduleData={timelineImage} />
      {faq && faq.length == 6 ? <FAQ faq={faq} /> : ""}
      <Footer />
    </div>
  );
}

export default SecondFold;
