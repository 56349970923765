import "./second-fold.css";

import Testimonials from "../testimonials/testimonials";
import AboutProgram from "../about-program/about-program";
import HowItWorks from "../how-it-works/how-it-works";
import ProgramSchedule from "../program-schedule/program-schedule";
import FAQ from "../faq/faq";
import Footer from "../footer/footer";

function SecondFold({ timelineImage, faq, howItWorks, testimonials, aboutArt }) {
  return (
    <div className="mobile-second-fold-container">
      {testimonials && testimonials.length == 3 ? (
        <div className="mobile-second-fold-testimonial-container">
          <Testimonials testimonials={testimonials} />
        </div>
      ) : (
        ""
      )}
      {aboutArt && aboutArt.length == 3 ? <AboutProgram aboutArt={aboutArt} /> : ""}
      {howItWorks && howItWorks.length == 4 ? <HowItWorks howItWorks={howItWorks} /> : ""}
      <ProgramSchedule programScheduleData={timelineImage} />
      {faq && faq.length == 6 ? <FAQ faq={faq} /> : ""}
      <Footer />
    </div>
  );
}

export default SecondFold;
