import { useState, useEffect } from "react";
import {
  useAddress,
  useActiveClaimConditionForWallet,
  useClaimNFT,
  useContract,
  useNFTBalance,
  useChain,
  useDisconnect,
  useContractRead,
  useBalance,
} from "@thirdweb-dev/react";
import { NATIVE_TOKEN_ADDRESS } from "@thirdweb-dev/sdk";
import * as amplitude from "@amplitude/analytics-browser";

import { ThirdWebChain } from "../../../../constants/common-constants";
import WizardAPI from "../../wizard-api";

import "./step3.css";
import StepLoader from "../../../common-components/step-loader/step-loader";

function LaunchStep3({ artistId, membershipId, stepChange, nftDetails, postLaunch }) {
  const address = useAddress();
  const [price, setPrice] = useState(nftDetails["price"]);
  const [allowlistPrice, setAllowlistPrice] = useState(nftDetails["allowlist_price"]);
  const [nftQuantity, setNftQuantity] = useState(1);
  const [maxMintingLimitMessage, setMaxMintingLimitMessageMessage] = useState("");
  const [showTransactionRejectedErrorMessage, setShowTransactionRejectedErrorMessage] = useState(false);
  const [showInsufficientBalanceMessage, setShowInSufficientBalanceMessage] = useState(false);
  const [currentMintedTokenMessage, setCurrentMintedTokenMessage] = useState("");
  const [platformFeesDetailsLoading, setPlatformFeesDetailsLoading] = useState(true);
  const [platformFees, setPlatformFees] = useState(-1);
  const [platformFeesPercentage, setPlatformFeesPercentage] = useState(-1);
  const [memberDetailsLoading, setMemberDetailsLoading] = useState(true);
  const [isMemberOnAllowlist, setIsMemberOnAllowlist] = useState(false);

  // __CONFIG__
  const tokenAddress = NATIVE_TOKEN_ADDRESS;
  let contractDetails;
  let contractDetailsLoading;
  let contractDetailsError;

  let claimConditionForWallet;
  let claimConditionForWalletLoading;
  let claimConditionForWalletError;

  let nftBalanceForWalletData;
  let nftBalanceForWalletLoading;
  let nftBalanceForWalletError;

  let walletBalanceOfUserData;
  let walletBalanceOfUserLoading;
  let walletBalanceOfUserError;

  let maxClaimableSupplyForWallet = -1;
  let priceForWallet = -1;
  let currentNftBalanceOfWallet = -1;
  let walletBalanceOfUser = -1;

  let transactionProcessing = false;
  let transactionProcessingStatus = "";
  let transactionProcessingError = false;

  const chain = useChain();
  const disconnect = useDisconnect();

  const getMemberDetails = async () => {
    const getMemberRes = await WizardAPI.getMemberDetails(artistId, membershipId, address, "");
    if (getMemberRes["success"] && getMemberRes["code"] === 200) {
      // console.log("launch step3.js memberRes>>", getMemberRes["data"]["on_allowlist"]);
      setIsMemberOnAllowlist(getMemberRes["data"]["on_allowlist"]);
      setMemberDetailsLoading(false);
    } else {
      stepChange(1);
    }
  };

  const precisionRound = (number, precision) => {
    var factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
  };

  const { contract, isLoading, error } = useContract(
    nftDetails.contract_address,
    nftDetails.token_id != "-1" ? "edition-drop" : undefined
  );

  contractDetails = contract;
  contractDetailsLoading = isLoading;
  contractDetailsError = error;
  // console.log("launch/step3.js contractDetails>>>>>>", contractDetailsLoading);
  // console.log("launch/step3.js contractDetailsError>>>>>>", contractDetailsError);
  // console.log("launch/step3.js contractDetails>>>>>>", contractDetails);

  const getPlatformDetails = async () => {
    const platformFeesData = await contract.platformFees.get();
    if (platformFeesData && platformFeesData["platform_fee_basis_points"] >= 0) {
      setPlatformFeesPercentage(platformFeesData["platform_fee_basis_points"] / 100);
      setPlatformFeesDetailsLoading(false);
    }
  };

  if (contract) {
    getPlatformDetails();
  }

  const claimConditionForWalletRes = useActiveClaimConditionForWallet(
    contract,
    address,
    nftDetails.token_id != "-1" ? nftDetails.token_id : undefined
  );
  claimConditionForWallet = claimConditionForWalletRes["data"];
  claimConditionForWalletLoading = claimConditionForWalletRes["isLoading"];
  claimConditionForWalletError = claimConditionForWalletRes["error"];
  // console.log("launch/step3.js claimConditionOfWallet>>>>>>", claimConditionForWallet);
  // console.log("launch/step3.js claimConditionOfWalletLoading>>>>>>", claimConditionForWalletLoading);
  // console.log("launch/step3.js claimConditionOfWalletError>>>>>>", claimConditionForWalletError);
  if (claimConditionForWalletRes["data"]) {
    maxClaimableSupplyForWallet = claimConditionForWalletRes["data"]["maxClaimablePerWallet"];
    priceForWallet = hexToInt(claimConditionForWalletRes["data"]["price"]["_hex"]) / 10 ** 18;
    // console.log("launch/step3.js platformFeesPercentage>>>>>>", platformFeesPercentage);
    // console.log("launch/step3.js priceOfNftForWallet>>>>>>", priceForWallet);
    // console.log("launch/step3.js platformFees>>>>>>", (priceForWallet * platformFeesPercentage) / 100);
    if (platformFees == -1 && platformFeesPercentage != -1) {
      setPlatformFees(precisionRound((priceForWallet * platformFeesPercentage) / 100, 5));
    }
  }

  let options = [];
  if (nftDetails.token_id !== "-1") {
    options = [Number(nftDetails.token_id), Number(nftDetails.claim_condition_id), address];
  } else {
    options = [Number(nftDetails.claim_condition_id), address];
  }
  const nftBalanceOfWalletRes = useContractRead(contract, "getSupplyClaimedByWallet", options);

  nftBalanceForWalletData = nftBalanceOfWalletRes["data"];
  nftBalanceForWalletLoading = nftBalanceOfWalletRes["isLoading"];
  nftBalanceForWalletError = nftBalanceOfWalletRes["error"];
  if (nftBalanceOfWalletRes["data"]) {
    currentNftBalanceOfWallet = hexToInt(nftBalanceOfWalletRes["data"]["_hex"]);
    // console.log("launch/step3.js currentNFTBalanceOfWallet>>>>>>", currentNftBalanceOfWallet);
  }
  // console.log("launch/step3.js nftBalanceLoading>>>>>>", nftBalanceForWalletLoading);
  // console.log("launch/step3.js nftBalanceProcessingError>>>>>>", nftBalanceForWalletError);

  const tokenId = nftDetails.token_id;
  const claimNFT = useClaimNFT(contract);

  transactionProcessing = claimNFT.isLoading;
  transactionProcessingError = claimNFT.isError;
  transactionProcessingStatus = claimNFT.status;
  // console.log("launch/step3.js transaction loading>>>>>>", transactionProcessing);
  // console.log("launch/step3.js transactionHasError>>>>>>", transactionProcessingError);
  // console.log("launch/step3.js transactionProcessingStatus>>>>>>", transactionProcessingStatus);
  // console.log("launch/step3.js transactionProcessingData>>>>>>", claimNFT.data);

  const walletBalanceOfUserRes = useBalance(tokenAddress);

  walletBalanceOfUserData = walletBalanceOfUserRes["data"];
  walletBalanceOfUserLoading = walletBalanceOfUserRes["isLoading"];
  walletBalanceOfUserError = walletBalanceOfUserRes["error"];

  if (walletBalanceOfUserData) {
    walletBalanceOfUser =
      hexToInt(walletBalanceOfUserData["value"]["_hex"]) / 10 ** walletBalanceOfUserData["decimals"];
  }

  const mintNFT = async () => {
    if (transactionProcessing) {
      return;
    }
    if (currentNftBalanceOfWallet >= maxClaimableSupplyForWallet) {
      return;
    }
    // __CONFIG__
    if (chain.chainId !== ThirdWebChain.chainId) {
      disconnect();
      stepChange(3);
      return;
    }

    if (!walletBalanceOfUserLoading && !walletBalanceOfUserError) {
      const balanceNeeded = priceForWallet * nftQuantity;
      if (walletBalanceOfUser < balanceNeeded) {
        setShowInSufficientBalanceMessage(true);
        return;
      }
    }

    setShowTransactionRejectedErrorMessage(false);
    setShowInSufficientBalanceMessage(false);
    transactionProcessing = true;
    // console.log("mint nft is called>>>>>>>", address, nftQuantity, tokenId);
    claimNFT.mutate({
      to: address,
      quantity: nftQuantity,
      tokenId: nftDetails.token_id != "-1" ? nftDetails.token_id : undefined,
    });
    // Trigger Amplitude Event
    try {
      amplitude.track("Checkout Button Clicked", {
        wallet_connected: address ? "True" : "False",
        minting: postLaunch ? "Perk1" : "Pass",
        artist_id: artistId,
        membership_id: membershipId,
        contract_address: nftDetails.contract_address,
        token_id: nftDetails.token_id,
        quantity: nftQuantity,
        button: "buy_now_primary_cta",
      });
    } catch (e) {}
  };

  function hexToInt(data) {
    return parseInt(data, 16);
  }

  const increaseNftQuantity = () => {
    if (transactionProcessing) {
      return;
    }
    setShowTransactionRejectedErrorMessage(false);
    setShowInSufficientBalanceMessage(false);
    let currentQuantity = nftQuantity;

    if (currentNftBalanceOfWallet >= maxClaimableSupplyForWallet) {
      setNftQuantity(0);
      return;
    }

    if (currentQuantity == maxClaimableSupplyForWallet - currentNftBalanceOfWallet) {
      return;
    }

    setNftQuantity(currentQuantity + 1);
  };

  const deceaseNftQuantity = () => {
    if (transactionProcessing) {
      return;
    }
    setShowTransactionRejectedErrorMessage(false);
    setShowInSufficientBalanceMessage(false);
    let currentQuantity = nftQuantity;
    if (currentQuantity <= 1) return;
    setNftQuantity(currentQuantity - 1);
  };

  // this useEffect is used to check the transaction status
  // if user rejected the transaction then show the error message on the same screen
  // if there is some error while processing the transaction then change the step to 4.2 which is error screen
  // if transaction is successful then change the step to 4.1 which is success screen
  useEffect(() => {
    if (claimNFT.error && claimNFT.error instanceof Error && claimNFT.error.message.indexOf("user rejected") >= 0) {
      setShowTransactionRejectedErrorMessage(true);
      // Trigger Amplitude Event
      try {
        amplitude.track("Mint Request Rejected", {
          wallet_connected: address ? "True" : "False",
          minting: postLaunch ? "Perk1" : "Pass",
          artist_id: artistId,
          membership_id: membershipId,
          contract_address: nftDetails.contract_address,
          token_id: nftDetails.token_id,
          source: "web",
        });
      } catch (e) {}
      return;
    }
    if (transactionProcessingStatus == "error") {
      stepChange(4.2, {
        address: address,
        tokenId: nftDetails.token_id,
        contractAddress: nftDetails.contract_address,
        mintCount: nftQuantity,
        transactionHash: "",
        transactionStatus: false,
      });
      // Trigger Amplitude Event
      try {
        amplitude.track("Mint Fail", {
          wallet_connected: address ? "True" : "False",
          minting: postLaunch ? "Perk1" : "Pass",
          artist_id: artistId,
          membership_id: membershipId,
          contract_address: nftDetails.contract_address,
          token_id: nftDetails.token_id,
          source: "web",
        });
      } catch (e) {}
    } else if (transactionProcessingStatus == "success") {
      stepChange(4.1, {
        address: address,
        tokenId: nftDetails.token_id,
        contractAddress: nftDetails.contract_address,
        mintCount: nftQuantity,
        transactionHash:
          nftDetails.token_id === "-1"
            ? claimNFT.data[0].receipt.transactionHash
            : claimNFT.data.receipt.transactionHash,
        transactionStatus: true,
      });
      // Trigger Amplitude Event
      try {
        amplitude.track("Mint Done", {
          wallet_connected: address ? "True" : "False",
          minting: postLaunch ? "Perk1" : "Pass",
          artist_id: artistId,
          membership_id: membershipId,
          contract_address: nftDetails.contract_address,
          token_id: nftDetails.token_id,
          source: "web",
        });
      } catch (e) {}
    }
  }, [transactionProcessingStatus]);

  // if the sufficient condition for minting is not complete then change the step to 1
  // if the address is not present then change the step to 2 (wallet connect screen)
  useEffect(() => {
    // console.log("launch.js/step3.js nft details>>>>>", nftDetails);
    if (!nftDetails.contract_address || !nftDetails.token_id || !nftDetails.claim_condition_id) {
      stepChange(1);
    }
    if (address) {
      getMemberDetails();
    } else {
      stepChange(2);
    }
    // Trigger Amplitude Event
    try {
      amplitude.track("Checkout Page Viewed", {
        wallet_connected: address ? "True" : "False",
        minting: postLaunch ? "Perk1" : "Pass",
        artist_id: artistId,
        membership_id: membershipId,
        contract_address: nftDetails.contract_address,
        token_id: nftDetails.token_id,
      });
    } catch (e) {}
  }, [address]);

  // this useEffect is used to fetch the token count of the user
  // the number of token user minted already and number of token user can mint
  useEffect(() => {
    if (transactionProcessing) {
      return;
    }

    // set default nftQuantity to zero if all NFTs claimed by the user.
    if (maxClaimableSupplyForWallet >= 0 && currentNftBalanceOfWallet >= 0) {
      let defaultQty = maxClaimableSupplyForWallet - currentNftBalanceOfWallet;
      if (defaultQty == 0) {
        setNftQuantity(0);
      }
    }

    if (currentNftBalanceOfWallet > 0) {
      setCurrentMintedTokenMessage(
        `You have already minted ${currentNftBalanceOfWallet} token${currentNftBalanceOfWallet > 1 ? "s" : ""}`
      );

      let numberOfTokenLeftToMint = maxClaimableSupplyForWallet - currentNftBalanceOfWallet;
      setMaxMintingLimitMessageMessage(
        `You can mint up to ${numberOfTokenLeftToMint} token${numberOfTokenLeftToMint > 1 ? "s" : ""}`
      );
    } else {
      setMaxMintingLimitMessageMessage(
        `You can mint up to ${maxClaimableSupplyForWallet} token${maxClaimableSupplyForWallet > 1 ? "s" : ""}`
      );
    }
  }, [maxClaimableSupplyForWallet, currentNftBalanceOfWallet]);

  useEffect(() => {
    if (contractDetailsError || claimConditionForWalletError || nftBalanceForWalletError || walletBalanceOfUserError) {
      console.log(
        "launch/step3.js contract error>>",
        contractDetailsError,
        claimConditionForWalletError,
        nftBalanceForWalletError,
        walletBalanceOfUserError
      );
      stepChange(1);
      window.location.reload();
    }
  }, [contractDetailsError, claimConditionForWalletError, nftBalanceForWalletError, walletBalanceOfUserError]);

  return (
    <>
      {contractDetailsLoading ||
      claimConditionForWalletLoading ||
      nftBalanceForWalletLoading ||
      platformFeesDetailsLoading ||
      memberDetailsLoading ? (
        <StepLoader />
      ) : (
        <div className="launch-step3-container">
          <div className="launch-step3-collectors-container"></div>
          <div className="launch-step3-checkout-container">
            <div className="launch-step3-checkout-box">
              <div className="launch-step3-checkout-box-header-container">
                <div className="launch-step3-checkout-box-header-text-container">
                  <div className="launch-step3-checkout-box-header-text">Checkout</div>
                </div>
              </div>
              <div className="launch-step3-checkout-box-quantity-container">
                <div className="launch-step3-checkout-box-nft-amount-container">
                  <div className="launch-step3-checkout-box-nft-decrement-container">
                    <img
                      className="launch-step3-checkout-box-nft-minus-image"
                      src={require("../../../../assets/minus.webp")}
                      alt="minus"
                      onClick={() => deceaseNftQuantity()}
                    />
                  </div>
                  <div className="launch-step3-checkout-box-nft-count-container">
                    <div className="launch-step3-checkout-box-nft-count-text">
                      {currentNftBalanceOfWallet >= maxClaimableSupplyForWallet ? 0 : nftQuantity}
                    </div>
                  </div>
                  <div className="launch-step3-checkout-box-nft-increment-container">
                    <img
                      className="launch-step3-checkout-box-nft-plus-image"
                      src={require("../../../../assets/plus.webp")}
                      alt="plus"
                      onClick={() => increaseNftQuantity()}
                    />
                  </div>
                </div>
                <div className="launch-step3-checkout-box-nft-message-container">
                  <div className="launch-step3-checkout-box-nft-message-text">{maxMintingLimitMessage}</div>
                  <div className="launch-step3-checkout-box-nft-message-text">{currentMintedTokenMessage}</div>
                </div>
              </div>
              <div className="launch-step3-checkout-box-order-details-container">
                <div className="launch-step3-checkout-box-order-details-header-container">
                  <div className="launch-step3-checkout-box-order-details-header-text">Your order</div>
                </div>

                <div className="launch-step3-checkout-box-order-details-price-breakdown-container">
                  <div className="launch-step3-checkout-box-order-details-price-breakdown-text-container">
                    <div className="launch-step3-checkout-box-order-details-price-breakdown-text">Price</div>
                  </div>
                  <div className="launch-step3-checkout-box-order-details-price-breakdown-price-container">
                    <div className="launch-step3-checkout-box-order-details-price-text">
                      {postLaunch
                        ? precisionRound(priceForWallet - platformFees, 5)
                        : precisionRound(price * nftQuantity, 5)}{" "}
                      ETH
                    </div>
                    {postLaunch ? (
                      ""
                    ) : isMemberOnAllowlist ? (
                      <div className="launch-step3-checkout-box-order-details-price-text-crossed" />
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                {postLaunch ? (
                  ""
                ) : isMemberOnAllowlist ? (
                  <div className="launch-step3-checkout-box-order-details-price-breakdown-container">
                    <div className="launch-step3-checkout-box-order-details-price-breakdown-text-container">
                      <div className="launch-step3-checkout-box-order-details-price-breakdown-text">
                        Allowlist price
                      </div>
                    </div>
                    <div className="launch-step3-checkout-box-order-details-price-breakdown-price-container">
                      <div className="launch-step3-checkout-box-order-details-price-text">
                        {postLaunch
                          ? precisionRound(priceForWallet - platformFees, 5)
                          : precisionRound(allowlistPrice * nftQuantity, 5)}{" "}
                        ETH
                      </div>
                      {postLaunch ? (
                        ""
                      ) : isMemberOnAllowlist ? (
                        ""
                      ) : (
                        <div className="launch-step3-checkout-box-order-details-price-text-crossed" />
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="launch-step3-checkout-box-order-details-price-breakdown-container">
                  <div className="launch-step3-checkout-box-order-details-price-breakdown-text-container">
                    <div className="launch-step3-checkout-box-order-details-price-breakdown-text">Platform fees</div>
                  </div>
                  <div className="launch-step3-checkout-box-order-details-price-breakdown-price-container">
                    <div className="launch-step3-checkout-box-order-details-price-text">
                      {precisionRound(platformFees * nftQuantity, 5)} ETH
                    </div>
                  </div>
                </div>
              </div>
              <div className="launch-step3-checkout-box-total-price-container">
                <div className="launch-step3-checkout-box-total-price-text-container">
                  <div className="launch-step3-checkout-box-total-price-text">Total</div>
                </div>
                <div className="launch-step3-checkout-box-total-price-value-container">
                  <div className="launch-step3-checkout-box-total-price-value-text">
                    {postLaunch
                      ? precisionRound(priceForWallet * nftQuantity, 5)
                      : currentNftBalanceOfWallet >= maxClaimableSupplyForWallet
                      ? 0
                      : precisionRound(priceForWallet * nftQuantity, 5)}{" "}
                    ETH
                  </div>
                </div>
              </div>
            </div>

            <div className="launch-step3-error-container">
              <div className="launch-step3-error-text">
                {showTransactionRejectedErrorMessage ? "Please approve the transaction to proceed." : ""}
                {showInsufficientBalanceMessage ? "You do not have sufficient balance in the wallet." : ""}
              </div>
            </div>
          </div>
          <div className="launch-step3-navigation-container">
            <div className="launch-step3-button-container">
              <div
                className="launch-step3-button"
                onClick={() => mintNFT()}
                style={{ background: nftQuantity > 0 ? "#000000" : "#607d8b" }}
              >
                <div className="launch-step3-button-text">
                  {transactionProcessing ? (
                    <img
                      className="launch-step3-button-loader"
                      src={require("../../../../assets/button-loader.gif")}
                      alt="button-loader"
                    />
                  ) : (
                    "Buy Now"
                  )}
                </div>
              </div>
            </div>
            <div className="launch-step3-thirdweb-container">
              <img
                className="launch-step3-thirdweb-logo"
                src={require("../../../../assets/thirdweb-logo.webp")}
                alt="thirdweb-logo"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default LaunchStep3;
