import "./step-button.css";

function StepButton({ width, height, buttonText, loading = false, disable = false }) {
  return (
    <div
      className="mobile-step-button-container"
      style={{ width: width, height: height, background: !disable ? "#000000" : "#607d8b" }}
    >
      {loading ? (
        <img
          className="launch-step3-button-loader"
          src={require("../../../../assets/button-loader.gif")}
          alt="button-loader"
        />
      ) : (
        buttonText
      )}
    </div>
  );
}

export default StepButton;
