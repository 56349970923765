import { useState, useEffect } from "react";
import "./post-launch-nft-media-container.css";
import { useAddress } from "@thirdweb-dev/react";
import { OpenseaViewNFTTokenURL } from "../../../constants/common-constants";
import NftMedia from "../nft-media/nft-media";
import auctionWinnerBackground from "../../../assets/auction-winner-background-image.gif";

function PostLaunchNftMediaContainer({ nftData, index, passCount }) {
  const [tokenId, setTokenId] = useState(-1);
  const [nextDropMintStartDate, setNextDropMintStartDate] = useState();
  const [nextDropMintEndDate, setNextDropMintEndDate] = useState();
  const [isMintAllowed, setIsMintAllowed] = useState(true);

  const address = useAddress();

  if (nftData && nftData && nftData["token_id"]) {
    if (nftData["token_id"] != -1 && nftData["token_id"] != tokenId) {
      setTokenId(nftData["token_id"]);
    }
  }

  const openNftDetails = (contractAddress, tokenId) => {
    let url = `${OpenseaViewNFTTokenURL}/${contractAddress}/${tokenId}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  useEffect(() => {
    if (nftData["future_drop"]) {
      let mintStartDate = new Date(`${nftData["mint_start"]}Z`);
      let mintStartMonth = new Intl.DateTimeFormat("default", {
        month: "long",
      }).format(mintStartDate);
      let dropMintStartDate = mintStartMonth.slice(0, 3) + " " + mintStartDate.getDate();
      // console.log("post-launch-nft-media-container.js dropMintStartDate>>>>>>", dropMintStartDate);
      setNextDropMintStartDate(dropMintStartDate);

      let mintEndDate = new Date(`${nftData["mint_end"]}Z`);
      let mintEndMonth = new Intl.DateTimeFormat("default", {
        month: "long",
      }).format(mintEndDate);
      let dropMintEndDate = mintEndMonth.slice(0, 3) + " " + mintEndDate.getDate();
      // console.log("post-launch-nft-media-container.js  dropMintEndDate>>>>>>", dropMintEndDate);
      setNextDropMintEndDate(dropMintEndDate);
    }
  }, []);

  useEffect(() => {
    if (address) {
      if (
        nftData["live"] &&
        nftData["nft_mint_transaction"] &&
        nftData["nft_mint_transaction"].length &&
        nftData["nft_mint_transaction"][0] == "limited_edition_raffle"
      ) {
        if (nftData["allowlist"] && nftData["allowlist"].length && nftData["live"]) {
          if (!nftData["allowlist"].includes(address.toLowerCase())) {
            setIsMintAllowed(false);
          }
        }
      }
    }
  }, [address]);

  const widthOfTheMintIndicator = () => {
    if (nftData["auction"] || isLimitedEditionRaffle(nftData)) {
      return "100%";
    } else {
      return `${100 / passCount}%`;
    }
  };

  const getBackgroundColorOfMintIndicator = (index) => {
    if (index < nftData["mint_count"]) {
      return "#00A0AA";
    } else {
      if (nftData["live"] && nftData["mint_count"] > 0) {
        return "#FFEC3F";
      } else {
        return "#E0DDEA";
      }
    }
  };

  const isAuctionOrLimitedEditionRaffle = (nftData) => {
    if (
      (nftData["auction"] && nftData["mint_count"] > 0) ||
      (nftData["nft_mint_transaction"] &&
        nftData["nft_mint_transaction"].length &&
        nftData["nft_mint_transaction"][0] == "limited_edition_raffle" &&
        nftData["mint_count"] > 0)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isLimitedEditionRaffle = (nftData) => {
    if (
      nftData["nft_mint_transaction"] &&
      nftData["nft_mint_transaction"].length &&
      nftData["nft_mint_transaction"][0] == "limited_edition_raffle"
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div
      className={nftData["live"] ? "gallery-item-container-live" : "gallery-item-container"}
      id={nftData["live"] ? "minting-live" : `drop${nftData["drop_number"]}`}
      key={nftData["drop_number"]}
      style={{
        border:
          (nftData["auction"] && nftData["mint_count"] > 0) ||
          (isLimitedEditionRaffle(nftData) && nftData["mint_count"] > 0)
            ? "1px solid #FDEA42"
            : "",
        backgroundImage: isAuctionOrLimitedEditionRaffle(nftData) ? `url(${auctionWinnerBackground})` : "",
        backgroundRepeat: isAuctionOrLimitedEditionRaffle(nftData) ? "no-repeat" : "",
        backgroundPosition: isAuctionOrLimitedEditionRaffle(nftData) ? "center" : "",
        backgroundSize: isAuctionOrLimitedEditionRaffle(nftData) ? "cover" : "",
        backgroundColor: !isMintAllowed ? "rgba(224, 221, 234, 0.4)" : "",
        opacity:
          passCount > 0 && nftData["mint_count"] == 0 && !nftData["live"] && !nftData["future_drop"] ? "0.4" : "",
      }}
    >
      <div className="gallery-item-title-container">
        {nftData.token_id != "-1" ? (
          <div className="gallery-icon-container">
            <img
              className="gallery-icon"
              src={require("../../../assets/linkout.webp")}
              onClick={() => {
                let url = `${OpenseaViewNFTTokenURL}/${nftData.contract_address}/${nftData.token_id}`;
                window.open(url, "_blank", "noopener,noreferrer");
              }}
              alt="linkout"
            />
          </div>
        ) : (
          ""
        )}
        <div
          className="gallery-item-title-text"
          style={{
            color: nftData["auction"] ? "#DE69A1" : nftData["future_drop"] ? "#C4C2CC" : "#00A0AA",
            fontWeight: nftData["future_drop"] ? "bold" : "",
            width: nftData["live"] ? "220px" : "180px",
          }}
        >
          {nftData["future_drop"] ? "Coming up next" : nftData["title"]}
        </div>
        <div
          className="drop-detail-text"
          style={{
            color: nftData["auction"]
              ? "rgba(239, 181, 195, 0.4)"
              : nftData["future_drop"]
              ? "rgba(224, 221, 234, 1)"
              : "rgba(0, 160, 170, 0.2)",
          }}
        >
          {nftData["auction"] ? "auction" : index == 0 ? "pass" : isLimitedEditionRaffle(nftData) ? "gift" : "mint"}
        </div>
      </div>

      {/* media */}
      <div className={nftData["live"] ? "gallery-media-container-live" : "gallery-media-container"}>
        {/* if there is one nft */}
        {nftData["nft_details"].length == 1 ? (
          <div
            style={{
              width: "100%",
              height: nftData["live"] ? "100%" : "100%",
            }}
          >
            <NftMedia
              index={index + "0"}
              height={nftData["live"] ? "100%" : "100%"}
              width={"100%"}
              live={nftData["live"]}
              nftDetails={nftData["nft_details"][0]}
              contractAddress={nftData["contract_address"]}
              nftData={nftData}
            />
          </div>
        ) : (
          ""
        )}
        {/* if there is two nft */}
        {nftData["nft_details"].length == 2 ? (
          <div className={nftData["live"] ? "gallery-media-container-2-live" : "gallery-media-container-2"}>
            <div className="gallery-media-container-2-first-nft-container">
              <NftMedia
                index={index + "0"}
                height={"100%"}
                width={"100%"}
                live={nftData["live"]}
                nftDetails={nftData["nft_details"][0]}
                contractAddress={nftData["contract_address"]}
                nftData={nftData}
              />
            </div>
            <div className="gallery-media-container-2-second-nft-container">
              <NftMedia
                index={index + "1"}
                height={"100%"}
                width={"100%"}
                live={nftData["live"]}
                nftDetails={nftData["nft_details"][1]}
                contractAddress={nftData["contract_address"]}
                nftData={nftData}
              />
            </div>
          </div>
        ) : (
          ""
        )}
        {/* if there is three nfts */}
        {nftData["nft_details"].length == 3 ? (
          <div className={nftData["live"] ? "gallery-media-container-3-live" : "gallery-media-container-3"}>
            <div
              className="gallery-media-container-3-nft-container"
              style={{
                height: "50%",
              }}
            >
              <NftMedia
                index={index + "0"}
                height={"100%"}
                width={"100%"}
                live={nftData["live"]}
                nftDetails={nftData["nft_details"][0]}
                contractAddress={nftData["contract_address"]}
                nftData={nftData}
              />
              <NftMedia
                index={index + "1"}
                height={"100%"}
                width={"100%"}
                live={nftData["live"]}
                nftDetails={nftData["nft_details"][1]}
                contractAddress={nftData["contract_address"]}
                nftData={nftData}
              />
            </div>
            <div
              className="gallery-media-container-3-nft-container"
              style={{
                height: "50%",
              }}
            >
              <div style={{ height: "100%", width: "100%" }}></div>
              <NftMedia
                index={index + "2"}
                height={"100%"}
                width={"100%"}
                live={nftData["live"]}
                nftDetails={nftData["nft_details"][2]}
                contractAddress={nftData["contract_address"]}
                nftData={nftData}
              />
            </div>
          </div>
        ) : (
          ""
        )}

        {/* if user has four nfts  */}
        {nftData["nft_details"].length > 3 ? (
          <div className={nftData["live"] ? "gallery-media-container-3-live" : "gallery-media-container-3"}>
            <div
              className="gallery-media-container-3-nft-container"
              style={{
                height: "50%",
              }}
            >
              <NftMedia
                index={index + "0"}
                height={"100%"}
                width={"100%"}
                live={nftData["live"]}
                nftDetails={nftData["nft_details"][0]}
                contractAddress={nftData["contract_address"]}
                nftData={nftData}
              />
              <NftMedia
                index={index + "1"}
                height={"100%"}
                width={"100%"}
                live={nftData["live"]}
                nftDetails={nftData["nft_details"][1]}
                contractAddress={nftData["contract_address"]}
                nftData={nftData}
              />
            </div>
            <div
              className="gallery-media-container-3-nft-container"
              style={{
                height: "50%",
              }}
            >
              <NftMedia
                index={index + "2"}
                height={"100%"}
                width={"100%"}
                live={nftData["live"]}
                nftDetails={nftData["nft_details"][2]}
                contractAddress={nftData["contract_address"]}
                nftData={nftData}
              />
              <NftMedia
                index={index + "3"}
                height={"100%"}
                width={"100%"}
                live={nftData["live"]}
                nftDetails={nftData["nft_details"][3]}
                contractAddress={nftData["contract_address"]}
                nftData={nftData}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      {/* //////////////////// */}
      {passCount > 0 && !nftData["future_drop"] && !nftData["auction"] && isMintAllowed ? (
        <div className="mint-indicator-container">
          {[...Array(nftData["auction"] || isLimitedEditionRaffle(nftData) ? 1 : passCount)].map((e, i) => (
            <div
              className="mint-indicator"
              key={i}
              style={{
                width: widthOfTheMintIndicator(),
                backgroundColor: getBackgroundColorOfMintIndicator(i),
                border: "2px solid white",
              }}
            ></div>
          ))}
        </div>
      ) : (
        ""
      )}
      <div className="galley-item-details-container">
        {(!nftData["live"] && nftData["auction"] && nftData["mint_count"] > 0) ||
        (isLimitedEditionRaffle(nftData) && nftData["mint_count"] > 0) ? (
          <div className="auction-winner-batch-container">
            <img
              className="auction-winner-batch"
              src={require("../../../assets/auction-winner-batch.webp")}
              alt="batch"
            />
          </div>
        ) : (
          ""
        )}
        {passCount > 0 && !nftData["future_drop"] && !nftData["auction"] && isMintAllowed ? (
          <div className="mint-count-text">
            {nftData["mint_count"]}/{nftData["auction"] || isLimitedEditionRaffle(nftData) ? 1 : passCount}
          </div>
        ) : (
          ""
        )}
        <div
          className="mint-status-text"
          style={{
            color: nftData["auction"] ? "#DE69A1" : nftData["future_drop"] ? "#C3C0CD" : "#00A0AA",
          }}
        >
          {nftData["live"]
            ? nftData["auction"]
              ? "Auction live"
              : "Mint live"
            : nftData["future_drop"]
            ? `${nextDropMintStartDate} - ${nextDropMintEndDate}`
            : nftData["auction"]
            ? nftData["mint_count"] > 0
              ? "You won this auction!"
              : "Auction Complete"
            : "Mint Complete"}
        </div>
      </div>
    </div>
  );
}

export default PostLaunchNftMediaContainer;
