import { useState, useEffect } from "react";
import WizardAPI from "../../wizard-api";
import "./step2.css";
import StepLoader from "../../../common-components/step-loader/step-loader";

import { ThirdWebChain } from "../../../../constants/common-constants";
import { useConnect, useAddress } from "@thirdweb-dev/react";
import { metamaskWallet, coinbaseWallet, trustWallet, rainbowWallet, walletConnect } from "@thirdweb-dev/react";
import * as amplitude from "@amplitude/analytics-browser";

const walletConnectConfig = walletConnect({ qrModal: "walletConnect" });
const coinbaseConfig = coinbaseWallet();
const metamaskConfig = metamaskWallet();
const trustWalletConfig = trustWallet();
const rainbowWalletConfig = rainbowWallet();

function LaunchStep2({ artistId, membershipId, stepChange }) {
  const connect = useConnect();
  const address = useAddress();
  const [loading, setLoading] = useState(false);
  const [walletConnectButtonClicked, setWalletConnectButtonClicked] = useState(false);

  const getMemberDetails = async () => {
    setLoading(true);
    const getMemberRes = await WizardAPI.getMemberDetails(artistId, membershipId, address, "");
    if (getMemberRes["success"] && getMemberRes["code"] === 200) {
      stepChange(3);
      setLoading(false);
    } else {
      alert("Something went wrong.");
      stepChange(1);
    }
    // Trigger Amplitude Event
    try {
      amplitude.track("Wallet Connected", {
        wallet_connected: address ? "True" : "False",
        minting: "Pass",
        artist_id: artistId,
        membership_id: membershipId,
      });
    } catch (e) {}
  };

  const handleConnect = async (wallet) => {
    // Trigger Amplitude Event
    try {
      amplitude.track("Select-Wallet Button Clicked", {
        wallet_connected: address ? "True" : "False",
        minting: "Pass",
        artist_id: artistId,
        membership_id: membershipId,
        button: wallet,
      });
    } catch (e) {}
    try {
      let w;
      setWalletConnectButtonClicked(true);
      if (wallet === "Metamask") {
        w = await connect(metamaskConfig, ThirdWebChain); // _CONFIG_
      } else if (wallet === "Coinbase") {
        w = await connect(coinbaseConfig, ThirdWebChain); // _CONFIG_
      } else if (wallet === "Rainbow") {
        w = await connect(rainbowWalletConfig, ThirdWebChain); // _CONFIG_
      } else if (wallet === "Trust-Wallet") {
        w = await connect(trustWalletConfig, ThirdWebChain); // _CONFIG_
      } else if (wallet === "Wallet-Connect") {
        w = await connect(walletConnectConfig, ThirdWebChain); // _CONFIG_
      }
    } catch (error) {
      setWalletConnectButtonClicked(false);
    }
  };

  useEffect(() => {
    if (address) {
      getMemberDetails();
    }
    // Trigger Amplitude Event
    try {
      amplitude.track("Select-Wallet Page Viewed", {
        wallet_connected: address ? "True" : "False",
        minting: "Pass",
        artist_id: artistId,
        membership_id: membershipId,
      });
    } catch (e) {}
  }, [address]);

  return (
    <>
      {loading ? (
        <StepLoader />
      ) : (
        <div className="launch-step2-container">
          <div className="connect-wallet-container">
            {walletConnectButtonClicked ? (
              <div className="wallet-connecting-container">
                <div className="wallet-connecting-text">
                  Please complete your wallet signin. Signing helps us know that you are the owner of the wallet
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="connect-wallet-text-container">
              <div className="connect-wallet-text">Choose your wallet</div>
            </div>
            <div className="connect-wallet-option-container" onClick={() => handleConnect("Metamask")}>
              <img
                className="connect-wallet-option-image"
                src={require("../../../../assets/metamask.webp")}
                alt="metamask-logo"
              />
            </div>
            <div className="connect-wallet-option-container" onClick={() => handleConnect("Coinbase")}>
              <img
                className="connect-wallet-option-image"
                src={require("../../../../assets/coinbase.webp")}
                alt="coinbase-logo"
              />
            </div>
            {/* <div
              className="connect-wallet-option-container"
              onClick={() => handleConnect("Rainbow")}
            >
              <img
                className="connect-wallet-option-image"
                src={require("../../../../assets/rainbow.webp")}
              />
            </div>
            <div
              className="connect-wallet-option-container"
              onClick={() => handleConnect("Trust-Wallet")}
            >
              <img
                className="connect-wallet-option-image"
                src={require("../../../../assets/trust-wallet.webp")}
              />
            </div> */}
            <div className="connect-wallet-option-container" onClick={() => handleConnect("Wallet-Connect")}>
              <img
                className="connect-wallet-option-image"
                src={require("../../../../assets/wallet-connect.webp")}
                alt="wallet-connect-logo"
              />
            </div>

            <div className="connect-wallet-thirdweb-image-container">
              <img
                className="connect-wallet-thirdweb-image"
                src={require("../../../../assets/thirdweb-logo.webp")}
                alt="thirdweb-logo"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default LaunchStep2;
