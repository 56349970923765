import { useState, useEffect } from "react";
import "./post-launch-auction.css";
import StepLoader from "../../../common-components/step-loader/step-loader";
import { useDisconnect, useAddress } from "@thirdweb-dev/react";

function PostLaunchAuction({
  artistId,
  membershipId,
  stepChange,
  nftDetails,
  updateManifoldWidgetLoadingStatus,
  manifoldWidgetLoaded,
}) {
  const [loading, setLoading] = useState(true);

  const address = useAddress();
  const disconnect = useDisconnect();
  // window.customElements.whenDefined("wcm-button").then((data) => {
  // console.log("custom element is defined>>>>>>>>>>>", data);
  // });

  useEffect(() => {
    // window.location.reload();

    // console.log(">>>>>>", customElements.get("wcm-butn"));
    const d = customElements.get("wcm-button");
    // const d = document.getElementById("wcm-button");
    // d.remove();
    // customElements.upgrade()
    // customElements
    // if (address) {
    // console.log("wallet", address);
    // disconnect();
    // localStorage.clear();
    // }
    if (manifoldWidgetLoaded) {
      setLoading(false);
    }
    window.addEventListener("m-marketplace_listing_loaded", (event) => {
      // console.log("event is loaded>>>>>>>", event, event.detail.listingId);
      if (event && event.detail && event.detail.listingId) {
        updateManifoldWidgetLoadingStatus(true);
        setLoading(false);
      }
    });

    return () => {
      window.removeEventListener("m-marketplace_listing_loaded", (event) => {});
    };
  }, []);

  // useEffect(() => {
  //   if (address) {
  //     console.log("disconnecte the wallet", address);
  //     disconnect();
  //   }
  // }, [address]);

  return (
    <>
      {loading ? <StepLoader /> : ""}
      <div className="mobile-post-launch-auction-container">
        <div className="mobile-post-launch-auction-powered-by-container">
          <img
            className="mobile-post-launch-auction-powered-by-image"
            src={require("../../../../../assets/powered-by-manifold.webp")}
            alt="powered-by-manifold"
          />
        </div>
        <div className="mobile-post-launch-bid-container">
          <div className="mobile-connect-wallet-container">
            <div
              data-widget="m-connect"
              data-delay-auth="true"
              data-network="1"
              data-multi="true"
              data-wallet-connect-project-id="c4d5b8f13dc459705ac962713b690945"
              data-show-chain={false}
              data-show-balance={false}
            ></div>
          </div>
          <div className="mobile-bid-form-container">
            <div data-widget="m-layout-complete-listing" data-id={nftDetails.mint_url} data-network="1"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PostLaunchAuction;
