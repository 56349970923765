import { useState, useEffect } from "react";
import { useAddress } from "@thirdweb-dev/react";
import { useContract, useOwnedNFTs, useNFTBalance } from "@thirdweb-dev/react";
import * as amplitude from "@amplitude/analytics-browser";

import "./step1.css";
import WizardAPI from "../../wizard-api";
import { OpenseaViewNFTTokenURL } from "../../../../../constants/common-constants";
import NftCardContainer from "../../../common-components/nft-card-container/nft-card-container";

function PostLaunchStep1({ artistId, membershipId, stepChange, nftDetails, setUserOwnedNftDetails }) {
  const _HexValues = ["4", "5", "6", "7", "8", "9", "A", "B", "C", "D", "E"];
  const [mintingLive, setMintingLive] = useState(false);
  const [mintLiveDateOn, setMintLiveDateOn] = useState({});
  const [mintEndCountdown, setMintEndCountdown] = useState({});
  const [userOwnLaunchNFT, setUserOwnLaunchNFT] = useState(false);
  const [collectors, setCollectors] = useState({});
  const [mintCountBeforeUpdate, setMintCountBeforeUpdate] = useState([]);
  const [initialDataSet, setInitialDataSet] = useState(false);

  const [email, setEmail] = useState("");
  const [removeNotification, setRemoveNotification] = useState(true);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [showButtonLoader, setShowButtonLoader] = useState(false);
  const [error, setError] = useState(false);

  const address = useAddress();

  let launchNftTokenId = nftDetails[nftDetails.length - 1].token_id;
  let launchNftContractAddress = nftDetails[nftDetails.length - 1].contract_address;
  let launchNftTitle = nftDetails[nftDetails.length - 1].title;
  let userHasPassNFT = false;
  const [allNftDataSet, setAllNftDataSet] = useState(false);

  let passNftDetails = [];

  let passNftDataLoading;
  let passNftData;
  let passNftDataOwnedError;

  let nftOwnedData = new Array(nftDetails.length);
  // if (address) {
  nftOwnedData[0] = FindNftOwned(launchNftContractAddress, address);
  passNftData = nftOwnedData[0][0];
  passNftDataLoading = nftOwnedData[0][1];
  passNftDataOwnedError = nftOwnedData[0][2];

  // console.log("mobile-post-launch/step1.js>>>>>>>>", passNftData, passNftDataLoading, passNftDataOwnedError);
  // }

  const downloadCalenderInvite = () => {
    if (showButtonLoader) return;
    window.location.href = nftDetails[0].calender_invite_url;
    setRemoveNotification(false);
    setNotificationMessage("Reminder downloaded! Import the invite into your calendar app to stay updated");
  };

  const findMintEndCountdownTime = () => {
    var mintEndDate = new Date(nftDetails[0].mint_end);
    mintEndDate = convertToLocalTimestamp(mintEndDate);
    const now = new Date();
    var sec_num = (mintEndDate - now) / 1000;
    var days = Math.floor(sec_num / (3600 * 24));
    var hours = Math.floor((sec_num - days * (3600 * 24)) / 3600);
    var minutes = Math.floor((sec_num - days * (3600 * 24) - hours * 3600) / 60);
    var seconds = Math.floor(sec_num - days * (3600 * 24) - hours * 3600 - minutes * 60);

    if (days < 10) {
      days = "0" + days;
    }
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    setMintEndCountdown({
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    });
  };

  const convertToLocalTimestamp = (timestamp) => {
    var preLaunchStartTime = timestamp + "Z";
    var datetime = new Date(preLaunchStartTime);
    return datetime;
  };

  const findCurrentPhase = () => {
    const mintStartTime = convertToLocalTimestamp(nftDetails[0].mint_start);
    const mintEndDate = convertToLocalTimestamp(nftDetails[0].mint_end);
    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    var now = new Date();
    var currentDateAndTime = now;

    // console.log("mint start time>>", mintStartTime);
    // console.log("mint end time>>", mintEndDate);
    // console.log("current time>>>>>>", currentDateAndTime);
    if (mintStartTime <= currentDateAndTime && currentDateAndTime < mintEndDate) {
      findMintEndCountdownTime();
      setMintingLive(true);
    } else {
      let d = new Date(mintStartTime);
      let time = new Intl.DateTimeFormat("default", {
        hour12: true,
        hour: "numeric",
        minute: "numeric",
      }).format(d);
      time = time.toUpperCase();

      let month = new Intl.DateTimeFormat("default", {
        month: "long",
      }).format(d);
      month = month.toUpperCase();

      setMintLiveDateOn({
        time: time,
        month: month,
        date: d.getDate(),
        year: d.getFullYear(),
        day: weekday[d.getDay()].toUpperCase(),
      });
      setMintingLive(false);
    }
    setInitialDataSet(true);
  };

  useEffect(() => {
    //checking if the user has launch nft or not
    // console.log("userownednft dat>>>>", passNftData);
    if (passNftData) {
      if (passNftData.length === 0) {
        setUserOwnLaunchNFT(false);
      } else {
        setUserOwnLaunchNFT(true);
      }
    }
  }, [passNftData]);

  useEffect(() => {
    findCurrentPhase();
    findMintEndCountdownTime();
    // fetchCollectorsDetails();
    // console.log("post-launch-step1.js>>>>>>", nftDetails);
  }, []);

  // prefill the email if email of user is already present
  useEffect(() => {
    if (address) {
      WizardAPI.getMemberDetails(artistId, membershipId, address, "").then((response) => {
        if (response && response.data && response.code == 200) {
          if (response.data.email) {
            setEmail(response.data.email);
          }
        }
      });
    } else {
      setEmail("");
    }
    // Trigger Amplitude Event
    try {
      amplitude.track("Home Page Viewed", {
        wallet_connected: address ? "True" : "False",
        minting: "Perk1",
        artist_id: artistId,
        membership_id: membershipId,
      });
    } catch (e) {}
  }, [address]);

  return (
    <>
      <div className="mobile-post-launch-step1-container">
        <div className="mobile-post-launch-step1-nft-container">
          {nftDetails.map((nftDetail, index) => (
            <NftCardContainer
              nftDetails={nftDetail}
              key={index}
              stepChange={stepChange}
              userHasPass={userOwnLaunchNFT}
              passDataLoading={address ? passNftDataLoading : false}
              launchNftContractAddress={launchNftContractAddress}
              launchNftTokenId={launchNftTokenId}
              nftIndex={index}
              artistId={artistId}
              membershipId={membershipId}
              launchNftTitle={launchNftTitle}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export const FindNftOwned = (contractAddress, address) => {
  const nftContractDetails = useContract(contractAddress);

  let contract = nftContractDetails.contract;
  let nftContractDetailsLoading = nftContractDetails.isLoading;
  let nftContractDetailsError = nftContractDetails.error;
  // console.log("contract details>>>>", contractAddress, contract);
  // console.log("error contact>>>>", contractAddress, nftContractDetailsError);
  let nftOwnedRes = useOwnedNFTs(contract, address);
  let nftOwnedData = nftOwnedRes["data"];
  let nftOwnedResLoading = nftOwnedRes["isLoading"];
  let nftOwnedResError = nftOwnedRes["error"];
  // console.log("from the function>>>>>>>>>>", contractAddress, nftOwnedData);
  // console.log("from the function loading", contractAddress, nftOwnedResLoading);
  // console.log("function error>>>>", contractAddress, nftOwnedResError);
  return [nftOwnedData, nftOwnedResLoading, nftOwnedResError];
};

export const FindAuctionWinner = (contractAddress, address, tokenId) => {
  const nftContractDetails = useContract(contractAddress);

  let contract = nftContractDetails.contract;
  let nftContractDetailsLoading = nftContractDetails.isLoading;
  let nftContractDetailsError = nftContractDetails.error;
  // console.log("contract details>>>>", contractAddress, contract);
  // console.log("error contact>>>>", contractAddress, nftContractDetailsError);
  const nftOwnedRes = useNFTBalance(contract, address, tokenId);
  let nftOwnedData = nftOwnedRes["data"];
  let nftOwnedResLoading = nftOwnedRes["isLoading"];
  let nftOwnedResError = nftOwnedRes["error"];
  // console.log("from the function>>>>>>>>>>", contractAddress, nftOwnedData);
  // console.log("from the function loading", contractAddress, nftOwnedResLoading);
  // console.log("function error>>>>", contractAddress, nftOwnedResError);
  return [nftOwnedData, nftOwnedResLoading, nftOwnedResError];
};

export default PostLaunchStep1;
