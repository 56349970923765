import { useEffect, useState } from "react";
import * as React from "react";

function PriceDetails({ data, launch }) {
  const [mintDate, setMintDate] = useState({});

  useEffect(() => {
    if (!data || !data["mint-start-time"] || !data["mint-end-time"]) {
      return;
    }
    let d = new Date(data["mint-start-time"]);
    if (launch) {
      d = new Date(data["mint-end-time"]);
    }
    let time = new Intl.DateTimeFormat("default", {
      hour12: true,
      hour: "numeric",
      minute: "numeric",
    }).format(d);
    time = time.toUpperCase();

    let month = new Intl.DateTimeFormat("default", {
      month: "short",
    }).format(d);
    month = month.toUpperCase();

    let weekday = new Intl.DateTimeFormat("default", {
      weekday: "short",
    }).format(d);
    weekday = weekday.toUpperCase();

    setMintDate({
      time: time,
      month: month,
      weekday: weekday,
      date: d.getDate(),
      year: d.getFullYear(),
    });
  }, []);

  return (
    <div
      style={{
        display: "block",
        width: "49vw",
        height: "calc(49vw * 0.23)",
        // border: "2px solid red"
      }}
    >
      <svg
        width={"49vw"}
        height={"calc(49vw * 0.23)"}
        viewBox="0 0 739 170"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.28326 10.8529V1L172.097 1.62097C171.954 3.69527 173.722 7.84388 178.513 7.84388C183.304 7.84388 184.501 4.04099 184.929 2.13954C288.585 2.13954 700.786 1.51858 714.474 1.51858C728.162 1.51858 737.43 13.6187 738 19.6687V151.5C738 163.946 723.599 168.094 718.752 168.613L184.074 169.234C184.074 166.123 181.507 162.492 177.658 162.492C173.808 162.492 170.5 169.322 170.5 168.613H1V155C4.5 155 10.4103 151.8 10.4103 145C10.4103 138.2 4.5 135 1 135V125.977C4 125.977 10.4103 123.297 10.4103 115C10.4103 106.703 4.5 104.5 1 104.715V95.3808C4.13678 95.2079 10.4103 91.8823 10.4103 84C10.4103 77.0279 4.5 73.082 1 73.082V63.7477C4.13678 63.5748 10.4103 60.4675 10.4103 53C10.4103 45.5325 6 42.4861 2.28326 42.4861V32.1145C4.99231 32.2874 10.4103 28.5 10.4103 21.743C10.4103 13.4458 4.99231 11.1987 2.28326 10.8529Z"
          fill="white"
          stroke="#E0DDEA"
          strokeWidth={0.855485}
        />
        <text
          fill="#131313"
          xmlSpace="preserve"
          style={{
            whiteSpace: "pre",
          }}
          fontFamily="Open Sans"
          fontSize={30.2194}
          fontWeight="bold"
          letterSpacing="0em"
        >
          <tspan x={50} y={81.9111}>
            {data.price}
          </tspan>
        </text>
        <text
          fill="#131313"
          xmlSpace="preserve"
          style={{
            whiteSpace: "pre",
          }}
          fontFamily="Open Sans"
          fontSize={12.4692}
          fontWeight="bold"
          letterSpacing="0em"
        >
          <tspan x={146.428} y={75.4733}>
            {"ETH"}
          </tspan>
        </text>
        <text
          fill="#131313"
          xmlSpace="preserve"
          style={{
            whiteSpace: "pre",
          }}
          fontFamily="Open Sans"
          fontSize={30.2194}
          fontWeight="bold"
          letterSpacing="0em"
        >
          <tspan x={252.209} y={81.9111}>
            {data["duration"]}
          </tspan>
        </text>
        <text
          fill="#131313"
          xmlSpace="preserve"
          style={{
            whiteSpace: "pre",
          }}
          fontFamily="Open Sans"
          fontSize={12.4692}
          fontWeight="bold"
          letterSpacing="0em"
        >
          <tspan x={281} y={75.4733}>
            {data["duration-unit"]}
          </tspan>
        </text>
        <text
          fill="#131313"
          xmlSpace="preserve"
          style={{
            whiteSpace: "pre",
          }}
          fontFamily="Open Sans"
          fontSize={9.65655}
          fontWeight={800}
          letterSpacing="0em"
        >
          <tspan x={82.1895} y={35.3822}>
            {data["price-cta"]}
          </tspan>
        </text>
        <text
          fill="#131313"
          xmlSpace="preserve"
          style={{
            whiteSpace: "pre",
          }}
          fontFamily="Open Sans"
          fontSize={9.65655}
          fontWeight={600}
          letterSpacing="0em"
        >
          <tspan x={266.467} y={35.1507}>
            {data["duration-cta"]}
          </tspan>
        </text>
        <text
          fill="#131313"
          xmlSpace="preserve"
          style={{
            whiteSpace: "pre",
          }}
          fontFamily="Open Sans"
          fontSize={30.2194}
          fontWeight="bold"
          letterSpacing="0em"
        >
          <tspan x={414.209} y={81.9111}>
            {data["collection"]}
          </tspan>
        </text>
        <text
          fill="#131313"
          xmlSpace="preserve"
          style={{
            whiteSpace: "pre",
          }}
          fontFamily="Open Sans"
          fontSize={12.4692}
          fontWeight="bold"
          letterSpacing="0em"
        >
          <tspan x={460.417} y={75.4733}>
            {data["collection-unit"]}
          </tspan>
        </text>
        <text
          fill="#131313"
          xmlSpace="preserve"
          style={{
            whiteSpace: "pre",
          }}
          fontFamily="Open Sans"
          fontSize={9.65655}
          fontWeight={600}
          letterSpacing="0em"
        >
          <tspan x={416.745} y={114.246}>
            {data["collection-price-cta"]}
          </tspan>
        </text>
        <text
          fill="#131313"
          xmlSpace="preserve"
          style={{
            whiteSpace: "pre",
          }}
          fontFamily="Open Sans"
          fontSize={30.2194}
          fontWeight="bold"
          letterSpacing="0em"
        >
          <tspan x={612.209} y={81.9111}>
            {data["auction"]}
          </tspan>
        </text>
        <text
          fill="#131313"
          xmlSpace="preserve"
          style={{
            whiteSpace: "pre",
          }}
          fontFamily="Open Sans"
          fontSize={12.4692}
          fontWeight="bold"
          letterSpacing="0em"
        >
          <tspan x={635.19} y={71.4733}>
            {data["auction-unit"]}
          </tspan>
        </text>
        <text
          fill="#131313"
          xmlSpace="preserve"
          style={{
            whiteSpace: "pre",
          }}
          fontFamily="Open Sans"
          fontSize={9.65655}
          fontWeight={600}
          letterSpacing="0em"
        >
          <tspan x={603.611} y={114.246}>
            {data["auction-cta"]}
          </tspan>
        </text>
        <path d="M201 44L715 44" stroke="#E0DDEA" strokeWidth={0.855485} />
        <path d="M201 98L715 98" stroke="#E0DDEA" strokeWidth={0.855485} />
        <g filter="url(#filter0_d_583_1121)">
          <path d="M20 98H191" stroke="#E0DDEA" strokeWidth={0.855485} />
        </g>
        <g filter="url(#filter1_d_583_1121)">
          <path d="M20 44H191" stroke="#E0DDEA" strokeWidth={0.855485} />
        </g>
        <path d="M372 15L372 123" stroke="#E0DDEA" strokeWidth={0.855485} />
        <path d="M548 15L548 123" stroke="#E0DDEA" strokeWidth={0.855485} />
        <g filter="url(#filter2_d_583_1121)">
          <path
            d="M19.4277 15.4277H190.572V122.572H19.4277V15.4277Z"
            stroke="#E0DDEA"
            strokeWidth={0.855485}
            shapeRendering="crispEdges"
          />
        </g>
        <g filter="url(#filter3_d_583_1121)">
          <path
            d="M19.4277 156.572H714.572V132.428H19.4277V156.572Z"
            stroke="#E0DDEA"
            strokeWidth={0.855485}
            shapeRendering="crispEdges"
          />
        </g>
        <path d="M201.428 15.4277H706.572V122.572H201.428V15.4277Z" stroke="#E0DDEA" strokeWidth={0.855485} />
        <path d="M201.428 15.4277H714.572V122.572H201.428V15.4277Z" stroke="#E0DDEA" strokeWidth={0.855485} />
        <g filter="url(#filter4_d_583_1121)">
          <text
            fill="#555555"
            xmlSpace="preserve"
            style={{
              whiteSpace: "pre",
            }}
            fontFamily="Open Sans"
            fontSize={9.84388}
            fontWeight={800}
            letterSpacing="0.16em"
          >
            <tspan textAnchor="middle" x={369.5} y={149.319}>
              {launch
                ? `MINTING CLOSES ON ${mintDate.weekday}, ${mintDate.month} ${mintDate.date} AT ${mintDate.time}`
                : `MINTING STARTS ON ${mintDate.weekday}, ${mintDate.month} ${mintDate.date} AT ${mintDate.time}`}
            </tspan>
          </text>
        </g>
        <defs>
          <filter
            id="filter0_d_583_1121"
            x={12}
            y={89.5723}
            width={187}
            height={16.8555}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation={4} />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.176471 0 0 0 0 0.717647 0 0 0 0 0.807843 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_583_1121" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_583_1121" result="shape" />
          </filter>
          <filter
            id="filter1_d_583_1121"
            x={12}
            y={35.5723}
            width={187}
            height={16.8555}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation={4} />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.176471 0 0 0 0 0.717647 0 0 0 0 0.807843 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_583_1121" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_583_1121" result="shape" />
          </filter>
          <filter
            id="filter2_d_583_1121"
            x={11}
            y={7}
            width={188}
            height={124}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation={4} />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.176471 0 0 0 0 0.717647 0 0 0 0 0.807843 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_583_1121" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_583_1121" result="shape" />
          </filter>
          <filter
            id="filter3_d_583_1121"
            x={11}
            y={124}
            width={712}
            height={41}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation={4} />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.176471 0 0 0 0 0.717647 0 0 0 0 0.807843 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_583_1121" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_583_1121" result="shape" />
          </filter>
          <filter
            id="filter4_d_583_1121"
            x={0}
            y={133.862}
            width={739}
            height={24.5077}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation={4} />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.176471 0 0 0 0 0.717647 0 0 0 0 0.807843 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_583_1121" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_583_1121" result="shape" />
          </filter>
        </defs>
      </svg>
    </div>
  );
}

export default PriceDetails;
