import { useState, useEffect } from "react";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";

import "./pre-launch.css";
import PreLaunchStep1 from "./Step1/step1";
import PreLaunchStep2 from "./Step2/step2";
import PreLaunchStep3 from "./Step3/step3";
import PreLaunchInvite from "./Step3.1/step3.1";
import PreLaunchStep4 from "./Step4/step4";
import PreLaunchStep5 from "./Step5/step5";

function PreLaunch({ nftDetails, disableAllowlist }) {
  const params = useParams();
  const navigate = useNavigate();
  const [artistId, setArtistId] = useState(nftDetails["artist_id"]);
  const [membershipId, setMembershipId] = useState(nftDetails["membership_id"]);
  const [inviteCodeStepSkip, setInviteCodeStepSkip] = useState(true);
  const [launchReminderData, setLaunchReminderData] = useState({});

  let [searchParams] = useSearchParams();
  const phase = searchParams.get("phase");
  const step = searchParams.get("step");
  let inviteCode = searchParams.get("invite_code");
  const [showStep, setShowStep] = useState(step);
  // console.log("pre-launch.js searchParams>>>>>>>>>>>", phase, step, inviteCode);

  const getNavigationUrlParams = (phase, step) => {
    let urlParams = "";
    if (inviteCode) {
      urlParams = `?invite_code=${inviteCode}` + "&phase=" + String(phase) + "&step=" + String(step);
    } else {
      urlParams = "?phase=" + String(phase) + "&step=" + String(step);
    }
    return urlParams;
  };

  const changeStep = (step, data = {}) => {
    if (step == "4") {
      if (data) {
        setInviteCodeStepSkip(data["skipStep"]);
      } else {
        setInviteCodeStepSkip(false);
      }
    } else if (step == "5") {
      if (data) {
        setLaunchReminderData(data);
      }
    }
    navigate({
      pathname: "/mobile/" + String(params.membershipSlug),
      search: getNavigationUrlParams("pre_launch", step),
    });
    setShowStep(step);
  };

  useEffect(() => {
    // console.log("mobile-pre-launch.js nft details>>>>>", nftDetails);
    setShowStep(step);
  }, [step]);

  return (
    <div className="mobile-pre-launch-container">
      <div className="mobile-pre-launch-nft-details-container ">
        {showStep == 1 ? (
          <PreLaunchStep1
            stepChange={changeStep}
            artistId={artistId}
            membershipId={membershipId}
            disableAllowlist={disableAllowlist}
            nftDetails={nftDetails}
          />
        ) : showStep == 2 ? (
          <PreLaunchStep2
            stepChange={changeStep}
            artistId={artistId}
            membershipId={membershipId}
            disableAllowlist={disableAllowlist}
          />
        ) : showStep == 3 ? (
          <PreLaunchStep3
            artistId={artistId}
            membershipId={membershipId}
            stepChange={changeStep}
            disableAllowlist={disableAllowlist}
          />
        ) : showStep == 4 ? (
          <PreLaunchStep4
            stepChange={changeStep}
            artistId={artistId}
            membershipId={membershipId}
            inviteCodeStepSkip={inviteCodeStepSkip}
            disableAllowlist={disableAllowlist}
          />
        ) : showStep == 5 ? (
          <PreLaunchStep5
            stepChange={changeStep}
            artistId={artistId}
            membershipId={membershipId}
            launchReminderData={launchReminderData}
            disableAllowlist={disableAllowlist}
          />
        ) : showStep == 3.1 ? (
          <PreLaunchInvite
            artistId={artistId}
            membershipId={membershipId}
            stepChange={changeStep}
            disableAllowlist={disableAllowlist}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default PreLaunch;
