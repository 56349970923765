import { useEffect, useState } from "react";
import * as React from "react";

function PriceDetailsLimitedEdition({ data, launch }) {
  const [mintDate, setMintDate] = useState({});

  useEffect(() => {
    if (!data || !data["mint-start-time"] || !data["mint-end-time"]) {
      return;
    }
    let d = new Date(data["mint-start-time"]);
    if (launch) {
      d = new Date(data["mint-end-time"]);
    }
    let time = new Intl.DateTimeFormat("default", {
      hour12: true,
      hour: "numeric",
      minute: "numeric",
    }).format(d);
    time = time.toUpperCase();

    let month = new Intl.DateTimeFormat("default", {
      month: "short",
    }).format(d);
    month = month.toUpperCase();

    let weekday = new Intl.DateTimeFormat("default", {
      weekday: "short",
    }).format(d);
    weekday = weekday.toUpperCase();

    setMintDate({
      time: time,
      month: month,
      weekday: weekday,
      date: d.getDate(),
      year: d.getFullYear(),
    });
  }, []);

  return (
    <div
      style={{
        display: "block",
        width: "49vw",
        height: "calc(49vw * 0.23)",
        // border: "2px solid red",
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="49vw" height="calc(49vw * 0.23)" fill="none" viewBox="0 0 798 170">
        <path
          fill="#fff"
          stroke="#E0DDEA"
          strokeWidth="0.855"
          d="M2.348 10.853V1l178.351.621c-.15 2.074 1.707 6.223 6.739 6.223 5.031 0 6.289-3.803 6.739-5.704 108.867 0 541.793-.621 556.169-.621 14.375 0 24.109 12.1 24.708 18.15V151.5c0 12.446-15.124 16.594-20.216 17.113l-561.56.621c0-3.111-2.695-6.742-6.739-6.742-4.043 0-7.517 6.83-7.517 6.121H1V155c3.676 0 9.883-3.2 9.883-10S4.676 135 1 135v-9.023c3.15 0 9.883-2.68 9.883-10.977S4.676 104.5 1 104.715v-9.334c3.294-.173 9.883-3.499 9.883-11.381 0-6.972-6.207-10.918-9.883-10.918v-9.334c3.294-.173 9.883-3.28 9.883-10.748S6.252 42.486 2.348 42.486V32.115c2.845.172 8.535-3.615 8.535-10.372 0-8.297-5.69-10.544-8.535-10.89z"
        ></path>
        <text
          fill="#131313"
          style={{ whiteSpace: "pre" }}
          fontFamily="Open Sans"
          fontSize="34.219"
          fontWeight="bold"
          letterSpacing="0em"
        >
          <tspan x="55" y="83.795">
            {data.price}
          </tspan>
        </text>
        <text
          fill="#131313"
          style={{ whiteSpace: "pre" }}
          fontFamily="Open Sans"
          fontSize="12.469"
          fontWeight="bold"
          letterSpacing="0em"
        >
          <tspan x="145.428" y="83.912">
            ETH
          </tspan>
        </text>
        <text
          fill="#131313"
          style={{ whiteSpace: "pre" }}
          fontFamily="Open Sans"
          fontSize="34.219"
          fontWeight="bold"
          letterSpacing="0em"
        >
          <tspan x="227.774" y="81.911">
            {data["duration"]}
          </tspan>
        </text>
        <text
          fill="#131313"
          style={{ whiteSpace: "pre" }}
          fontFamily="Open Sans"
          fontSize="12"
          fontWeight="bold"
          letterSpacing="0em"
        >
          <tspan x="256.565" y="73.246">
            {data["duration-unit"]}
          </tspan>
        </text>
        <text
          fill="#131313"
          style={{ whiteSpace: "pre" }}
          fontFamily="Open Sans"
          fontSize="9.657"
          fontWeight="800"
          letterSpacing="0em"
        >
          <tspan x="86.19" y="35.382">
            {data["price-cta"]}
          </tspan>
        </text>
        <text
          fill="#131313"
          style={{ whiteSpace: "pre" }}
          fontFamily="Open Sans"
          fontSize="9.657"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="242.032" y="35.151">
            {data["duration-cta"]}
          </tspan>
        </text>
        <text
          fill="#131313"
          style={{ whiteSpace: "pre" }}
          fontFamily="Open Sans"
          fontSize="34.219"
          fontWeight="bold"
          letterSpacing="0em"
        >
          <tspan x="362.032" y="79.911">
            {data["collection"]}
          </tspan>
        </text>
        <text
          fill="#131313"
          style={{ whiteSpace: "pre" }}
          fontFamily="Open Sans"
          fontSize="12"
          fontWeight="bold"
          letterSpacing="0em"
        >
          <tspan x="390.823" y="73.246">
            {data["collection-unit"]}
          </tspan>
        </text>
        <text
          fill="#131313"
          style={{ whiteSpace: "pre" }}
          fontFamily="Open Sans"
          fontSize="9.657"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="372.082" y="113.382">
            {data["collection-price-cta"]}
          </tspan>
        </text>
        <text
          fill="#131313"
          style={{ whiteSpace: "pre" }}
          fontFamily="Open Sans"
          fontSize="9.657"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="525.978" y="114.246">
            {data["raffle-cta"]}
          </tspan>
        </text>
        <text
          fill="#131313"
          style={{ whiteSpace: "pre" }}
          fontFamily="Open Sans"
          fontSize="34.219"
          fontWeight="bold"
          letterSpacing="0em"
        >
          <tspan x="501.405" y="79.911">
            {data["raffle"]}
          </tspan>
        </text>
        <text
          fill="#131313"
          style={{ whiteSpace: "pre" }}
          fontFamily="Open Sans"
          fontSize="34.219"
          fontWeight="bold"
          letterSpacing="0em"
        >
          <tspan x="664.727" y="76.749">
            {data["auction"]}
          </tspan>
        </text>
        <text
          fill="#131313"
          style={{ whiteSpace: "pre" }}
          fontFamily="Open Sans"
          fontSize="12"
          fontWeight="bold"
          letterSpacing="0em"
        >
          <tspan x="536.251" y="65.61">
            {data["raffle-uint"].split(" ")[0]}
          </tspan>
          <tspan x="536.251" y="81.61">
            {data["raffle-uint"].split(" ")[1]}
          </tspan>
        </text>
        <text
          fill="#131313"
          style={{ whiteSpace: "pre" }}
          fontFamily="Open Sans"
          fontSize="12"
          fontWeight="bold"
          letterSpacing="0em"
        >
          <tspan x="688.882" y="73.246">
            {data["auction-unit"]}
          </tspan>
        </text>
        <text
          fill="#131313"
          style={{ whiteSpace: "pre" }}
          fontFamily="Open Sans"
          fontSize="9.657"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="635.48" y="114.246">
            {data["auction-cta"]}
          </tspan>
        </text>
        <path stroke="#E0DDEA" strokeWidth="0.855" d="M201 44h552.61M201 98h552.61"></path>
        <g filter="url(#filter0_d_1813_4108)">
          <path stroke="#E0DDEA" strokeWidth="0.855" d="M20 98h171"></path>
        </g>
        <g filter="url(#filter1_d_1813_4108)">
          <path stroke="#E0DDEA" strokeWidth="0.855" d="M20 44h171"></path>
        </g>
        <path stroke="#E0DDEA" strokeWidth="0.855" d="M337.438 15v108M473.483 15.125v108M609.887 15v108"></path>
        <g filter="url(#filter2_d_1813_4108)">
          <path
            stroke="#E0DDEA"
            strokeWidth="0.855"
            d="M19.428 15.428h171.144v107.144H19.428V15.428z"
            shapeRendering="crispEdges"
          ></path>
        </g>
        <g filter="url(#filter3_d_1813_4108)">
          <path
            stroke="#E0DDEA"
            strokeWidth="0.855"
            d="M19.428 156.572h735.765v-24.144H19.428v24.144z"
            shapeRendering="crispEdges"
          ></path>
        </g>
        <path stroke="#E0DDEA" strokeWidth="0.855" d="M201.428 15.428h545.053v107.144H201.428V15.428z"></path>
        <path stroke="#E0DDEA" strokeWidth="0.855" d="M201.428 15.428h552.425v107.144H201.428V15.428z"></path>
        <g filter="url(#filter4_d_583_1121)">
          <text
            fill="#555555"
            xmlSpace="preserve"
            style={{
              whiteSpace: "pre",
            }}
            fontFamily="Open Sans"
            fontSize={9.84388}
            fontWeight={800}
            letterSpacing="0.16em"
          >
            <tspan textAnchor="middle" x={369.5} y={149.319}>
              {launch
                ? `MINTING CLOSES ON ${mintDate.weekday}, ${mintDate.month} ${mintDate.date} AT ${mintDate.time}`
                : `MINTING STARTS ON ${mintDate.weekday}, ${mintDate.month} ${mintDate.date} AT ${mintDate.time}`}
            </tspan>
          </text>
        </g>
        <defs>
          <filter
            id="filter0_d_1813_4108"
            width="187"
            height="16.855"
            x="12"
            y="89.572"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            ></feColorMatrix>
            <feOffset></feOffset>
            <feGaussianBlur stdDeviation="4"></feGaussianBlur>
            <feComposite in2="hardAlpha" operator="out"></feComposite>
            <feColorMatrix values="0 0 0 0 0.176471 0 0 0 0 0.717647 0 0 0 0 0.807843 0 0 0 0.1 0"></feColorMatrix>
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1813_4108"></feBlend>
            <feBlend in="SourceGraphic" in2="effect1_dropShadow_1813_4108" result="shape"></feBlend>
          </filter>
          <filter
            id="filter1_d_1813_4108"
            width="187"
            height="16.855"
            x="12"
            y="35.572"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            ></feColorMatrix>
            <feOffset></feOffset>
            <feGaussianBlur stdDeviation="4"></feGaussianBlur>
            <feComposite in2="hardAlpha" operator="out"></feComposite>
            <feColorMatrix values="0 0 0 0 0.176471 0 0 0 0 0.717647 0 0 0 0 0.807843 0 0 0 0.1 0"></feColorMatrix>
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1813_4108"></feBlend>
            <feBlend in="SourceGraphic" in2="effect1_dropShadow_1813_4108" result="shape"></feBlend>
          </filter>
          <filter
            id="filter2_d_1813_4108"
            width="188"
            height="124"
            x="11"
            y="7"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            ></feColorMatrix>
            <feOffset></feOffset>
            <feGaussianBlur stdDeviation="4"></feGaussianBlur>
            <feComposite in2="hardAlpha" operator="out"></feComposite>
            <feColorMatrix values="0 0 0 0 0.176471 0 0 0 0 0.717647 0 0 0 0 0.807843 0 0 0 0.1 0"></feColorMatrix>
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1813_4108"></feBlend>
            <feBlend in="SourceGraphic" in2="effect1_dropShadow_1813_4108" result="shape"></feBlend>
          </filter>
          <filter
            id="filter3_d_1813_4108"
            width="752.621"
            height="41"
            x="11"
            y="124"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            ></feColorMatrix>
            <feOffset></feOffset>
            <feGaussianBlur stdDeviation="4"></feGaussianBlur>
            <feComposite in2="hardAlpha" operator="out"></feComposite>
            <feColorMatrix values="0 0 0 0 0.176471 0 0 0 0 0.717647 0 0 0 0 0.807843 0 0 0 0.1 0"></feColorMatrix>
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1813_4108"></feBlend>
            <feBlend in="SourceGraphic" in2="effect1_dropShadow_1813_4108" result="shape"></feBlend>
          </filter>
          <filter
            id="filter4_d_1813_4108"
            width="292.491"
            height="24.508"
            x="220.885"
            y="133.862"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            ></feColorMatrix>
            <feOffset></feOffset>
            <feGaussianBlur stdDeviation="4"></feGaussianBlur>
            <feComposite in2="hardAlpha" operator="out"></feComposite>
            <feColorMatrix values="0 0 0 0 0.176471 0 0 0 0 0.717647 0 0 0 0 0.807843 0 0 0 0.1 0"></feColorMatrix>
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1813_4108"></feBlend>
            <feBlend in="SourceGraphic" in2="effect1_dropShadow_1813_4108" result="shape"></feBlend>
          </filter>
        </defs>
      </svg>
    </div>
  );
}

export default PriceDetailsLimitedEdition;
