import "./how-it-works.css";

function HowItWorks({ howItWorks }) {
  return (
    <div id="how_it_works" className="how-it-works-container">
      <div className="how-it-works-header">
        <div className="how-it-works-header-text">HOW IT WORKS</div>
      </div>
      <div className="how-it-works-details">
        <div className="how-it-works-first">
          <img className="how-it-works-image" src={howItWorks[0]["icon"]} alt="magnet" />
          <div className="how-it-works-text-container">
            <div className="how-it-works-text">{howItWorks[0]["text"]}</div>
          </div>
        </div>
        <div className="how-it-works-second">
          <img className="how-it-works-image" src={howItWorks[1]["icon"]} alt="leaf" />
          <div className="how-it-works-text-container">
            <div className="how-it-works-text">{howItWorks[1]["text"]}</div>
          </div>
        </div>
        <div className="how-it-works-third">
          <img className="how-it-works-image" src={howItWorks[2]["icon"]} alt="hammer" />
          <div className="how-it-works-text-container">
            <div className="how-it-works-text">{howItWorks[2]["text"]}</div>
          </div>
        </div>
        <div className="how-it-works-fourth">
          <img className="how-it-works-image" src={howItWorks[3]["icon"]} alt="bell" />
          <div className="how-it-works-text-container">
            <div className="how-it-works-text">{howItWorks[3]["text"]}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowItWorks;
