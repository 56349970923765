import "./footer.css";

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-text">Powered by</div>
      <div className="footer-logo">
        <img className="footer-logo-image" src={require("../../../assets/footer-logo.webp")} alt="footer-logo" />
      </div>
    </div>
  );
}

export default Footer;
