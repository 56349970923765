import { API } from "../constants/common-constants";

function PhaseDetailsAPI() {
  const API_ENDPOINT = "http://localhost:8000/artist";
  async function getArtistDetails(artistSlug) {
    var data = JSON.stringify({
      artist_slug: artistSlug,
    });
    const res = await fetch(API.GetArtistDetails, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: data,
    });
    const response = await res.json();
    // console.log("artist_details>>>>>", response);
    return response;
  }

  async function getMemberShipDetails(membershipSlug) {
    var data = JSON.stringify({
      membership_slug: membershipSlug,
    });
    const res = await fetch(API.GetMembershipDetails, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: data,
    });
    const response = await res.json();
    // console.log("membership details", response);
    return response;
  }

  async function getPhaseDetails(artistId, membershipId) {
    var data = JSON.stringify({
      artist_id: artistId,
      membership_id: membershipId,
    });
    const res = await fetch(API.GetPhaseDetails, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: data,
    });
    const response = await res.json();
    // console.log("membership details", response);
    return response;
  }

  async function getArtistThirdWebClientID(artistSlug) {
    var data = JSON.stringify({
      artist_slug: artistSlug,
    });
    const res = await fetch(API.GetArtistThirdWebClientID, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: data,
    });
    const response = await res.json();
    // console.log("ArtistThirdWebClientID Response>>>>>", response);
    return response;
  }

  async function getInitialData(artistSlug, membershipSlug) {
    var data = JSON.stringify({
      artist_slug: artistSlug,
      membership_slug: membershipSlug,
    });
    const res = await fetch(API.GetInitialData, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: data,
    });
    const response = await res.json();
    // console.log("getInitialData Response>>>>>", response);
    return response;
  }

  return {
    getArtistDetails,
    getMemberShipDetails,
    getPhaseDetails,
    getArtistThirdWebClientID,
    getInitialData,
  };
}

export default PhaseDetailsAPI();
