import "./footer.css";

function Footer() {
  return (
    <div className="mobile-footer-container">
      <div className="mobile-footer-text">Powered by</div>
      <div className="mobile-footer-logo">
        <img
          className="mobile-footer-logo-image"
          src={require("../../../../assets/footer-logo.webp")}
          alt="footer-logo"
        />
      </div>
    </div>
  );
}

export default Footer;
