import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAddress } from "@thirdweb-dev/react";
import WizardAPI from "../../wizard-api";
import "./step3.1.css";
import StepLoader from "../../../common-components/step-loader/step-loader";
import { useWalletAddress } from "../../../../../hooks/useWalletAddress";

function PreLaunchInvite({ artistId, membershipId, stepChange, disableAllowlist }) {
  const address = useAddress();

  let [searchParams] = useSearchParams();
  let phase = searchParams.get("phase");
  let step = searchParams.get("step");
  let refereeCode = searchParams.get("invite_code");

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [inviteCode, setInviteCode] = useState(refereeCode);
  const [showButtonLoader, setShowButtonLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [projectName, setProjectName] = useState("");

  const toInputUppercase = (e) => {
    if (showButtonLoader) return;
    e.target.value = ("" + e.target.value).toUpperCase();
  };

  const onValueChange = (evt) => {
    if (showButtonLoader) return;
    setInviteCode(evt.target.value);
  };

  const handleFocus = (e) => {
    if (showButtonLoader) return;
    setError(false);
    e.currentTarget.select();
  };

  const skipStep = () => {
    if (showButtonLoader) return;
    stepChange(4, { skipStep: "true" });
  };

  const validateInviteCode = async () => {
    if (showButtonLoader) return;
    setShowButtonLoader(true);

    if (!inviteCode) {
      setShowButtonLoader(false);
      return;
    }

    if (!address) {
      stepChange(3);
    }

    const validateInviteCodeRes = await WizardAPI.validateInviteCode(
      artistId,
      membershipId,
      address,
      inviteCode.toUpperCase()
    );
    if (validateInviteCodeRes["success"] && validateInviteCodeRes["code"] === 200) {
      // console.log("pre-launch.js/step3.1.js validate inviteCode res>>>>>>", validateInviteCodeRes);
      setShowButtonLoader(false);
      if (validateInviteCodeRes["data"]["on_allowlist"]) {
        stepChange(4);
      } else {
        if (validateInviteCodeRes["message"] === "Invalid Code") {
          setErrorMessage("The Invite Code you've entered is not valid");
        } else {
          setErrorMessage("The Invite Code you've entered has already been used");
        }
        setError(true);
      }
    } else {
      setShowButtonLoader(false);
    }
  };

  const fetchStepDetails = async () => {
    setLoading(true);
    const stepDetailsRes = await WizardAPI.getStepDetails(artistId, membershipId, "pre_launch", 1);
    // console.log("pre-launch.js/step3.1.js step details>>>>>>", stepDetailsRes);

    if (stepDetailsRes["success"] && stepDetailsRes["code"] === 200) {
      // console.log("pre-launch.js/step3.1.js project name>>>>>>", stepDetailsRes["data"]["project-name"]);
      setProjectName(stepDetailsRes["data"]["project-name"]);
      getMemberDetails();
    } else {
      setLoading(false);
      alert("Something went wrong. Please refresh page.");
      stepChange(1);
    }
  };

  const getMemberDetails = async () => {
    if (!address) stepChange(3);
    const getMemberRes = await WizardAPI.getMemberDetails(artistId, membershipId, address, "");
    // console.log("pre-launch.js/ste3.1.js member details>>>>>>", getMemberRes);
    if (getMemberRes["success"] && getMemberRes["code"] === 200) {
      if (getMemberRes["data"]["on_allowlist"]) {
        stepChange(4, getMemberRes["data"]);
      }

      // the the invite code is present in the url then validate the invite code
      if (inviteCode) {
        // console.log("pre-launch.js/ste3.js invite code of user>>>>>>>>>>", inviteCode);
        await validateInviteCode();
      }

      setLoading(false);
    } else {
      alert("Something went wrong.");
      stepChange(1);
    }
  };

  useEffect(() => {
    if (disableAllowlist) {
      // if the pre-launch is over then change step to 1
      stepChange(1);
    }

    if (address) {
      // if address is present then fetch step details
      fetchStepDetails();
    } else {
      // if address is not present then change step to connect wallet or wallet is disconnected
      stepChange(3);
    }
  }, [address]);

  return (
    <>
      {loading ? (
        <StepLoader />
      ) : (
        <div className="mobile-pre-launch-invite-container">
          <div className="mobile-pre-launch-invite-heading-container">
            <div className="mobile-pre-launch-invite-heading-text">Do you have an invite code for {projectName}?</div>
          </div>
          <div className="mobile-pre-launch-invite-input-container ">
            <input
              className="mobile-invite-input-box"
              defaultValue={refereeCode}
              placeholder="Enter invite code"
              onInputCapture={onValueChange}
              onFocus={handleFocus}
              autoFocus
              onInput={toInputUppercase}
              disabled={showButtonLoader}
              maxLength={16}
            />

            <div className="mobile-pre-launch-error-container">{error ? errorMessage : ""}</div>
          </div>
          <div className="mobile-pre-launch-invite-navigation-container">
            <div className="mobile-pre-launch-button-container" onClick={validateInviteCode}>
              <div className="mobile-pre-launch-button-text">
                {showButtonLoader ? (
                  <img
                    className="mobile-pre-launch-invite-code-button-loader"
                    src={require("../../../../../assets/button-loader.gif")}
                    alt="button-loader"
                  />
                ) : (
                  "Verify code"
                )}
              </div>
            </div>
          </div>
          <div className="mobile-pre-launch-invite-skip-container" onClick={skipStep}>
            No code? Proceed to win a private mint spot in a raffle.
          </div>
        </div>
      )}
    </>
  );
}

export default PreLaunchInvite;
