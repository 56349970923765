import { useEffect } from "react";

export const useExternalScript = (url) => {
  useEffect(() => {
    let script = document.querySelector(`script[src="${url}"]`);

    if (!script) {
      // console.log("useExternalScript.js injecting script >>", url);
      script = document.createElement("script");
      script.src = url;
      document.body.appendChild(script);
    } else {
      // console.log("useExternalScript.js script is already present>>", script);
    }
  }, [url]);
};
