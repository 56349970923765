import { useState, useEffect } from "react";

import "./program-schedule.css";

function ProgramSchedule({ programScheduleData }) {
  const [programSchedule, setProgramSchedule] = useState([]);

  useEffect(() => {
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    var currentDateAndTime = new Date();
    for (let i = 0; i < programScheduleData.length; i++) {
      let eventStartDateAndTime = new Date(programScheduleData[i]["event-start-date"]);
      let eventEndDateAndTime = new Date(programScheduleData[i]["event-end-date"]);

      let eventStartTime = eventStartDateAndTime.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      let eventEndTime = eventEndDateAndTime.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });

      let eventDuration = Math.abs(eventEndDateAndTime - eventStartDateAndTime) / 36e5;
      let eventStartMonth = month[eventStartDateAndTime.getMonth()].slice(0, 3);
      let eventEndMonth = month[eventEndDateAndTime.getMonth()].slice(0, 3);

      let eventStartWeekDay = weekday[eventStartDateAndTime.getDay()].slice(0, 3);
      let eventEndWeekDay = weekday[eventEndDateAndTime.getDay()].slice(0, 3);

      let eventStartDate = eventStartDateAndTime.getDate();
      let eventEndDate = eventEndDateAndTime.getDate();

      let eventStarts = eventStartWeekDay + ", " + eventStartMonth + " " + eventStartDate + " at " + eventStartTime;
      let eventEnds = eventEndWeekDay + ", " + eventEndMonth + " " + eventEndDate + " at " + eventEndTime;

      programScheduleData[i] = {
        ...programScheduleData[i],
        "event-start-date-numeric": eventStartDate < 10 ? "0" + eventStartDate : eventStartDate,
        "event-end-date-numeric": eventEndDate < 10 ? "0" + eventEndDate : eventEndDate,
        "event-duration": eventDuration + " HRS",
        "event-start-month": eventStartMonth,
        "event-end-month": eventEndMonth,
        "event-start-time": eventStartTime,
        "event-end-time": eventEndTime,
        "event-live":
          eventStartDateAndTime <= currentDateAndTime && currentDateAndTime < eventEndDateAndTime ? true : false,
        "event-starts": eventStarts,
        "event-ends": eventEnds,
      };
    }
    setProgramSchedule(programScheduleData);
  }, [programSchedule]);

  return (
    <div id="program-schedule" className="program-schedule-container">
      <div className="program-schedule-header">
        <div className="program-schedule-header-text">SCHEDULE</div>
      </div>
      <div className="program-schedule-table-container">
        <table className="schedule-table" cellPadding="0" cellSpacing="0">
          <thead>
            <tr className="schedule-table">
              <th>Event</th>
              <th>Starts</th>
              <th>Ends</th>
            </tr>
          </thead>

          <tbody>
            {programSchedule.map((item) => {
              return (
                <tr
                  key={item["event-title"]}
                  style={{
                    backgroundColor: item["event-live"] ? "#FEF2F5" : "",
                  }}
                >
                  <td>{item["event-title"].replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())}</td>
                  <td>{item["event-starts"]}</td>
                  <td>{item["event-ends"]}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ProgramSchedule;
