import { useEffect, useState } from "react";
import * as React from "react";

function MembershipDescTicketPartTwo({ data, launch }) {
  const [mintDate, setMintDate] = useState({});

  useEffect(() => {
    if (!data || !data["mint-start-time"] || !data["mint-end-time"]) {
      return;
    }
    let d = new Date(data["mint-start-time"]);
    if (launch) {
      d = new Date(data["mint-end-time"]);
    }
    let time = new Intl.DateTimeFormat("default", {
      hour12: true,
      hour: "numeric",
      minute: "numeric",
    }).format(d);
    time = time.toLowerCase();

    let month = new Intl.DateTimeFormat("default", {
      month: "short",
    }).format(d);
    month = month.toUpperCase();

    let weekday = new Intl.DateTimeFormat("default", {
      weekday: "short",
    }).format(d);
    weekday = weekday.toUpperCase();

    setMintDate({
      time: time,
      month: month,
      weekday: weekday,
      date: d.getDate(),
      year: d.getFullYear(),
    });
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "80vw",
        height: "calc(80vw * 0.23)",
        maxWidth: "400px",
        // border: "2px solid red",
      }}
    >
      <svg width="200" height="80" viewBox="0 0 200 76" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="mticket">
          <path
            id="ticket"
            d="M1.32596 6.32366V0.942871L120.261 1.28199C120.161 2.41479 121.405 4.68038 124.776 4.68038C128.147 4.68038 128.892 2.25589 129.193 1.21749C202.138 1.21749 175.574 1.22551 185.206 1.22551C194.838 1.22551 198.272 7.8335 198.674 11.1375V64.9454C198.674 71.7422 191.525 74.377 188.114 74.6602L128.689 74.6306C128.689 72.9314 126.883 70.9491 124.174 70.9491C121.465 70.9491 119.659 73.2146 119.659 74.6306L1.32596 74.2915V69.1939C2.93121 69.1356 6.47887 68.0611 6.47887 63.53C6.47887 58.9988 3.26924 57.4061 1.32596 57.5828V52.4852C3.53337 52.3908 6.47887 50.5594 6.47887 46.2548C6.47887 41.9502 3.53337 40.6852 1.32596 40.3076V35.21C3.53337 35.1156 6.51433 33.4904 6.51433 29.4123C6.51433 25.3343 3.23236 23.9764 1.32596 23.5988V17.9348C2.93753 17.2348 6.47887 16.802 6.47887 12.2708C6.47887 7.73966 3.23236 6.51246 1.32596 6.32366Z"
            fill="white"
            stroke="#E0DDEA"
            strokeWidth="0.811135"
          />
          <g id="Frame 1395">
            <rect x="132.379" y="10" width="57" height="56" rx="3.24454" fill="#2DB7CE" fillOpacity="0.1" />
            <path
              id="Rectangle 2716"
              d="M132.379 13.3144C132.379 11.5225 133.832 10.0698 135.623 10.0698H185.75C187.542 10.0698 188.995 11.5225 188.995 13.3144V22.5732H132.379V13.3144Z"
              fill="#2DB7CE"
              fillOpacity="0.1"
            />
            <g id="Rectangle 2719">
              <mask id="path-4-inside-1_3566_16155" fill="white">
                <path d="M132.453 34.77H189.066V53.5166H132.453V34.77Z" />
              </mask>
              <path d="M132.453 34.77H189.066V53.5166H132.453V34.77Z" fill="white" />
              <path
                d="M187.444 34.77V53.5166H190.689V34.77H187.444ZM134.075 53.5166V34.77H130.831V53.5166H134.075Z"
                fill="#2DB7CE"
                fillOpacity="0.1"
                mask="url(#path-4-inside-1_3566_16155)"
              />
            </g>
            <text
              id="22"
              fill="#2DB7CE"
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Open Sans"
              fontSize="18.2581"
              fontWeight="bold"
              letterSpacing="0em"
            >
              <tspan x="150.43" y="50.106">
                {mintDate.date}
              </tspan>
            </text>
            <text
              id="december"
              fill="#2DB7CE"
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Open Sans"
              fontSize="8"
              fontWeight="bold"
              letterSpacing="0.16em"
            >
              <tspan x="151.833" y="30.7998">
                {mintDate.month}
              </tspan>
            </text>
            <text
              id="9:30 am"
              fill="#2DB7CE"
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Open Sans"
              fontSize="8"
              fontWeight="600"
              letterSpacing="0.08em"
            >
              <tspan x="143.818" y="19.7598">
                {mintDate.time}
              </tspan>
            </text>
            <text
              id="2023"
              fill="#2DB7CE"
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Open Sans"
              fontSize="8"
              fontWeight="800"
              letterSpacing="0.48em"
            >
              <tspan x="145.744" y="62.8662">
                {mintDate.year}
              </tspan>
            </text>
          </g>
          <g id="Group 9485">
            <text
              id="0.06"
              fill="#131313"
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Open Sans"
              fontSize="18.5874"
              fontWeight="bold"
              letterSpacing="0em"
            >
              <tspan x="27.5957" y="46.5834">
                {data.price}
              </tspan>
            </text>
            <text
              id="ETH"
              fill="#131313"
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Open Sans"
              fontSize="9.68821"
              fontWeight="bold"
              letterSpacing="0em"
            >
              <tspan x="85.4219" y="46.0656">
                ETH
              </tspan>
            </text>
            <g id="reMember Pass">
              <text
                fill="#131313"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Open Sans"
                fontSize="8.81146"
                fontWeight="600"
                letterSpacing="0em"
              >
                <tspan x="48.1694" y="21.1176">
                  {data["price-cta"]}
                </tspan>
              </text>
            </g>
            <path id="Vector 51" d="M12.9395 24.6123L112.646 24.6123" stroke="#E0DDEA" strokeWidth="0.811135" />
            <path id="Vector 56" d="M12.9395 53.9165L112.646 53.9165" stroke="#E0DDEA" strokeWidth="0.811135" />
            <path
              id="Rectangle 135"
              d="M13.0735 9.76934H112.24V66.4268H13.0735V9.76934Z"
              stroke="#E0DDEA"
              strokeWidth="0.811135"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default MembershipDescTicketPartTwo;
