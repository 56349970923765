import { useState, useEffect } from "react";
import { useAddress } from "@thirdweb-dev/react";

import "./step1.css";
import WizardAPI from "../../wizard-api";
import MembershipDescTicketPartOne from "../../../common-components/membership-desc-ticket-part-one/membership-desc-ticket-part-one";
import MembershipDescTicketPartTwo from "../../../common-components/membership-desc-ticket-part-two/membership-desc-ticket-part-two";
import MembershipDescTicketPartTwoForAllowlist from "../../../common-components/membership-desc-ticket-part-two-for-allowlist/membership-desc-ticket-part-two-for-allowlist";
import StepLoader from "../../../common-components/step-loader/step-loader";
import StepButton from "../../../common-components/step-button/step-button";
import ThirdWebLogo from "../../../common-components/thirdweb-logo/thirdweb-logo";
import EventDate from "../../../common-components/event-date/event-date";

function PreLaunchStep1({ artistId, membershipId, stepChange, disableAllowlist, nftDetails }) {
  const [loading, setLoading] = useState(true);
  const [stepDetails, setStepDetails] = useState({});
  const [allowlisted, setAllowlisted] = useState(false);
  const [changeStepDetails, setChangeStepDetails] = useState(true);
  const address = useAddress();

  const fetchStepDetails = async () => {
    const stepDetailsRes = await WizardAPI.getStepDetails(artistId, membershipId, "pre_launch", 1);
    // console.log("pre-launch.js/step1.js step details>>>>>>", stepDetailsRes);
    if (stepDetailsRes["success"] && stepDetailsRes["code"] === 200) {
      setStepDetails(stepDetailsRes["data"]);
      setLoading(false);
    } else {
      alert("Something went wrong. Please refresh page.");
    }
  };

  useEffect(() => {
    fetchStepDetails();
  }, []);

  useEffect(() => {
    if (address) {
      WizardAPI.getMemberDetails(artistId, membershipId, address, "").then((response) => {
        if (response && response.data && response.data.on_allowlist === true) setAllowlisted(true);
      });
    } else {
      setAllowlisted(false);
    }
  }, [address]);

  return (
    <>
      {loading ? (
        <StepLoader />
      ) : (
        <div className="mobile-pre-launch-step1-container">
          <div className="mobile-pre-launch-event-date-container">
            <EventDate data={stepDetails} mint={true} />
            <div className="mobile-pre-launch-event-date-line-container"></div>
            <div className="mobile-pre-launch-event-date-end-container"></div>
          </div>

          <div className="mobile-pre-launch-step1-card-container">
            <div className="mobile-pre-launch-step1-card-title-container ">
              <div className="mobile-pre-launch-step1-card-title ">{nftDetails.title}</div>
            </div>

            {changeStepDetails ? (
              <div className="mobile-pre-launch-step1-card-media-container">
                <div
                  className="mobile-pre-launch-step1-card-media"
                  style={{
                    backgroundImage: nftDetails["media_type"] === "image" ? `url(${nftDetails["media"]})` : "",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                  }}
                >
                  {nftDetails["media_type"] != "image" ? (
                    <video className="mobile-pre-launch-step1-card-video " controls muted>
                      <source src={nftDetails["media"]} type="video/mp4" />
                    </video>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              <div className="mobile-pre-launch-step1-details-container">
                <div className="mobile-pre-launch-step1-membership-desc-ticket-part-one-container">
                  <MembershipDescTicketPartOne data={stepDetails} launch={false} />
                </div>
                <div className="mobile-pre-launch-step1-membership-sub-desc-container">
                  <div className="mobile-pre-launch-step1-membership-sub-desc">
                    {stepDetails["membership-sub-description"]}
                  </div>
                </div>
                <div className="mobile-pre-launch-step1-membership-desc-ticket-part-two-container">
                  {allowlisted ? (
                    <MembershipDescTicketPartTwoForAllowlist data={stepDetails} launch={false} />
                  ) : (
                    <MembershipDescTicketPartTwo data={stepDetails} launch={false} />
                  )}
                </div>
              </div>
            )}
            <div className="mobile-pre-launch-step1-card-button-container">
              <div
                className="mobile-pre-launch-step1-change-step-details-container"
                onClick={() => {
                  setChangeStepDetails(!changeStepDetails);
                }}
              >
                <img
                  className="mobile-pre-launch-step1-change-step-details-image"
                  src={require("../../../../../assets/flip.webp")}
                  alt="flip"
                />
              </div>

              <div className="mobile-pre-launch-step1-allowlist-price-container">{stepDetails["allowlist-text"]}</div>
              <div onClick={() => stepChange(2)}>
                <StepButton buttonText={stepDetails["button-cta"]} width="150px" height="45px" />
              </div>

              <ThirdWebLogo width="140px" />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PreLaunchStep1;
