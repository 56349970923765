import { useState, useEffect } from "react";
import "./post-launch-auction.css";

import { useExternalScript } from "../../../../hooks/useExternalScript";
import { useExternalStylesheet } from "../../../../hooks/useExternalStylesheet";

function PostLaunchAuction({
  artistId,
  membershipId,
  stepChange,
  nftDetails,
  updateManifoldWidgetLoadingStatus,
  manifoldWidgetLoaded,
}) {
  const [loading, setLoading] = useState(true);

  // external script and stylesheet for manifold auction
  const marketplaceWidgetScript = useExternalScript("https://marketplace.manifoldxyz.dev/3.5.4/marketplace.umd.min.js");
  const marketplaceWidgetStylesheet = useExternalStylesheet(
    "https://marketplace.manifoldxyz.dev/3.5.4/marketplace.css"
  );
  const marketplaceAdditionalCss = useExternalStylesheet(
    "https://marketplace.manifoldxyz.dev/3.5.4/marketplace.manifold-light.css"
  );

  const connectWidgetScript = useExternalScript("https://connect.manifoldxyz.dev/3.1.0/connect.umd.min.js");
  const connectWidgetStylesheet = useExternalStylesheet("https://connect.manifoldxyz.dev/3.1.0/connect.css");
  const connectWidgetAdditionalStylesheet = useExternalStylesheet(
    "https://connect.manifoldxyz.dev/3.1.0/connect.manifold-light.css"
  );

  const walletIdentityScript = useExternalScript("https://identity.manifoldxyz.dev/2.1.0/walletIdentity.umd.min.js");
  const walletIdentityStylesheet = useExternalStylesheet("https://identity.manifoldxyz.dev/2.1.0/walletIdentity.css");

  useEffect(() => {
    if (manifoldWidgetLoaded) {
      setLoading(false);
    }
    window.addEventListener("m-marketplace_listing_loaded", (event) => {
      // console.log("post-launch-auction.js event is loaded>>", event, event.detail.listingId);
      if (event && event.detail && event.detail.listingId) {
        updateManifoldWidgetLoadingStatus(true);
        setLoading(false);
      }
    });

    return () => {
      window.removeEventListener("m-marketplace_listing_loaded", (event) => {});
    };
  }, []);

  return (
    <>
      {loading ? (
        <div className="post-launch-auction-loading-container">
          <img className="step-loader-image" src={require("../../../../assets/step-loader.png")} alt="loader" />
        </div>
      ) : (
        ""
      )}
      <div className="post-launch-auction-container">
        <div className="post-launch-auction-powered-by-container">
          <img
            className="post-launch-auction-powered-by-image"
            src={require("../../../../assets/powered-by-manifold.webp")}
            alt="powered-by-manifold"
          />
        </div>
        <div className="post-launch-bid-container">
          <div className="connect-wallet-container">
            <div
              data-widget="m-connect"
              data-delay-auth="true"
              data-network="1"
              data-show-chain={false}
              data-show-balance={false}
            ></div>
          </div>
          <div className="bid-form-container">
            <div data-widget="m-layout-complete-listing" data-id={nftDetails.mint_url} data-network="1"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PostLaunchAuction;
