import { useState, useEffect } from "react";
import WizardAPI from "../../wizard-api";
import StepLoader from "../../../common-components/step-loader/step-loader";
import "./step4.css";
import { useWalletAddress } from "../../../../../hooks/useWalletAddress";
import { useAddress } from "@thirdweb-dev/react";

function PreLaunchStep4({ artistId, membershipId, stepChange, inviteCodeStepSkip, disableAllowlist }) {
  const address = useAddress();
  const [loading, setLoading] = useState(true);
  const [stepDetails, setStepDetails] = useState({});
  const [email, setEmail] = useState("");
  const [onAllowlist, setOnAllowlist] = useState(false);
  const [removeNotification, setRemoveNotification] = useState(true);
  const [notificationMessage, setNotificationMessage] = useState();
  const [showButtonLoader, setShowButtonLoader] = useState(false);
  const [showBackToHomeButton, setShowBackToHomeButton] = useState(false);
  const [error, setError] = useState(false);
  const [changeMainButtonText, setChangeMainButtonText] = useState(false);

  const onValueChange = (evt) => {
    if (showButtonLoader) return;
    setEmail(evt.target.value);
  };

  const handleFocus = (e) => {
    if (showButtonLoader) return;
    setError(false);
    e.currentTarget.select();
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const storeEmailAddress = async () => {
    if (showButtonLoader) return;
    setShowButtonLoader(true);

    if (changeMainButtonText) {
      stepChange(1);
      return;
    }
    if (email === "") {
      setShowButtonLoader(false);
      return;
    }
    if (!validateEmail(email)) {
      setError(true);
      setShowButtonLoader(false);
      return;
    }
    if (!address) {
      stepChange(3);
    }
    const storeEmailRes = await WizardAPI.storeEmail(artistId, membershipId, address, email);
    // console.log("pre-launch.js/step4.js  storeUserEmailRes", storeEmailRes);
    if (storeEmailRes["success"] && storeEmailRes["code"] === 200) {
      setShowButtonLoader(false);
      // if the user is on allowlist then send user to step5
      if (onAllowlist) {
        stepChange(5, { emailInvite: "true" });
      } else {
        setRemoveNotification(false);
        setShowBackToHomeButton(true);
        setNotificationMessage("Done! We'll send you email reminders closer to the date of mint.");
        setChangeMainButtonText(true);
      }
    } else {
      setShowButtonLoader(false);
    }
  };

  const downloadCalenderInvite = () => {
    if (showButtonLoader) return;
    window.location.href = stepDetails["calendar-invite"];
    // if the user is on allowlist then send user to step5
    if (onAllowlist) {
      stepChange(5, { calenderInvite: "true" });
    } else {
      setRemoveNotification(false);
      setShowBackToHomeButton(true);
      setNotificationMessage("Reminder downloaded! Import the invite into your calendar app to stay updated");
      setChangeMainButtonText(true);
    }
  };

  const fetchStepDetails = async () => {
    if (!address) return;
    const getMemberRes = await WizardAPI.getMemberDetails(artistId, membershipId, address, "");
    // console.log("pre-launch.js/step4.js membersDetails>>>>>>", getMemberRes);
    if (getMemberRes["success"] && getMemberRes["code"] === 200) {
      if (getMemberRes["data"]["email"]) {
        setEmail(getMemberRes["data"]["email"]);
      }
      setOnAllowlist(getMemberRes["data"]["on_allowlist"]);
      const stepDetailsRes = await WizardAPI.getStepDetails(artistId, membershipId, "pre_launch", 3);
      // console.log("pre-launch.js/step4.js stepDetails>>>>>>>", stepDetailsRes["data"]);
      if (stepDetailsRes["success"] && stepDetailsRes["code"] === 200) {
        if (getMemberRes["data"]["on_allowlist"]) {
          setStepDetails(stepDetailsRes["data"]["on-allowlist"]);
          // console.log(">>>>>", stepDetailsRes["data"]["on-allowlist"]);
        } else {
          setStepDetails(stepDetailsRes["data"]["not-on-allowlist"]);
        }

        setLoading(false);
      } else {
        alert("Something went wrong.");
        stepChange(1);
      }
    } else {
      alert("Something went wrong.");
      stepChange(1);
    }
  };

  useEffect(() => {
    if (disableAllowlist) {
      // if the pre-launch is over then change step to 1
      stepChange(1);
    }

    if (address) {
      // if address is present then fetch step details
      fetchStepDetails();
    } else {
      // if address is not present then change step to connect wallet or wallet is disconnected
      stepChange(3);
    }
  }, [address]);

  return (
    <>
      {loading ? (
        <StepLoader />
      ) : (
        <div className="mobile-pre-launch-step4-container">
          <div className="mobile-pre-launch-step4-details-container">
            {onAllowlist ? (
              <>
                <div className="mobile-pre-launch-step4-on-allowlist-heading-container">
                  <div className="mobile-pre-launch-step4-on-allowlist-design1">
                    <img
                      className="mobile-step4-design1-image"
                      src={require("../../../../../assets/step4-design3.webp")}
                      alt="heart-3"
                    />
                  </div>
                  <div className="mobile-pre-launch-step4-on-allowlist-design2">
                    <img
                      className="mobile-step4-design2-image"
                      src={require("../../../../../assets/step4-design1.webp")}
                      alt="heart-2"
                    />
                  </div>

                  <div className="mobile-pre-launch-step4-on-allowlist-heading-text ">
                    {stepDetails["main-heading"]}
                  </div>
                </div>

                <div className="mobile-pre-launch-step4-on-allowlist-subheading-container">
                  <div className="mobile-pre-launch-step4-on-allowlist-subheading-text">
                    {stepDetails["sub-heading"]}
                  </div>
                  <div className="mobile-pre-launch-step4-on-allowlist-design3">
                    <img
                      className="mobile-step4-design3-image"
                      src={require("../../../../../assets/step4-design2.webp")}
                      alt="heart-2"
                    />
                  </div>
                </div>

                <div className="mobile-pre-launch-step4-on-allowlist-input-box-container">
                  <input
                    defaultValue={email}
                    className="mobile-on-allowlist-input-box"
                    placeholder="Enter email address"
                    onInputCapture={onValueChange}
                    disabled={showButtonLoader}
                    onFocus={handleFocus}
                    autoFocus
                  />
                </div>

                <div className="mobile-step4-error-container-on-allowlist">
                  {error ? "Please enter a valid email address" : ""}
                </div>
              </>
            ) : (
              <>
                <div className="mobile-pre-launch-step4-not-on-allowlist-container">
                  <div className="mobile-pre-launch-step4-not-on-allowlist-heading-1-image-container">
                    <img
                      className="mobile-pre-launch-step4-not-on-allowlist-heading-1-image"
                      src={require("../../../../../assets/raffle.webp")}
                      alt="raffle"
                    />
                  </div>
                  <div className="mobile-pre-launch-step4-not-on-allowlist-text-container">
                    <div className="mobile-pre-launch-step4-not-on-allowlist-text">{stepDetails["sub-heading-1"]}</div>
                  </div>
                  <div className="mobile-pre-launch-step4-not-on-allowlist-heading-1-container">
                    <div className="mobile-pre-launch-step4-not-on-allowlist-heading-1-text-container">
                      <div className="mobile-pre-launch-step4-not-on-allowlist-heading-1-text">
                        We have entered your wallet in a raffle for {stepDetails["total-chances"]} allowlist spot
                        {stepDetails["total-chances"] > 1 ? "s" : ""} to get the pass for{" "}
                        {stepDetails["allowlist-price"]} ETH
                      </div>
                    </div>
                  </div>
                  <div className="mobile-pre-launch-step4-not-on-allowlist-heading-2-container">
                    <div className="mobile-pre-launch-step4-not-on-allowlist-heading-2-text">
                      {stepDetails["sub-heading-3"]}
                    </div>
                  </div>
                  <input
                    className="mobile-input-box"
                    defaultValue={email}
                    placeholder="Enter email address"
                    onInputCapture={onValueChange}
                    onFocus={handleFocus}
                    disabled={showButtonLoader}
                    autoFocus
                  />
                  {removeNotification ? (
                    ""
                  ) : (
                    <>
                      <div className="mobile-pre-launch-step4-notification-container">
                        <div className="mobile-pre-launch-step4-notification-text-container">{notificationMessage}</div>
                      </div>
                    </>
                  )}
                  <div className="mobile-step4-error-container-not-on-allowlist">
                    {error ? "Please enter a valid email address" : ""}
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="mobile-pre-launch-step4-navigation-container" style={{ flex: onAllowlist ? "45%" : "20%" }}>
            <div className="mobile-pre-launch-step4-main-button-container">
              <div
                style={{ width: onAllowlist ? "190px" : "220px" }}
                className="mobile-pre-launch-step4-main-button"
                onClick={storeEmailAddress}
              >
                <div className="mobile-pre-launch-step4-main-button-text">
                  {showButtonLoader ? (
                    <img
                      className="mobile-pre-launch-step4-button-loader"
                      src={require("../../../../../assets/button-loader.gif")}
                      alt="button-loader"
                    />
                  ) : changeMainButtonText ? (
                    "Back home"
                  ) : (
                    stepDetails["button-cta"]
                  )}
                </div>
              </div>
            </div>
            {changeMainButtonText ? (
              ""
            ) : (
              <>
                <div className="mobile-pre-launch-step4-decision-container">or</div>
                <div className="mobile-pre-launch-step4-secondary-button-container">
                  <div className="mobile-pre-launch-step4-secondary-button-text" onClick={downloadCalenderInvite}>
                    {stepDetails["secondary-button-cta"]}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default PreLaunchStep4;
