import { useEffect } from "react";

export const useExternalStylesheet = (url) => {
  useEffect(() => {
    let styleSheet = document.querySelector(`link[href="${url}"]`);

    if (!styleSheet) {
      // console.log("useExternalStylesheet.js injecting stylesheet>>>", url);
      const style = document.createElement("link");
      style.href = url;
      style.rel = "stylesheet";
      document.head.appendChild(style);
    } else {
      // console.log(" useExternalStylesheet.js stylesheet is already present>>", styleSheet);
    }
  }, [url]);
};
