import { useState, useEffect } from "react";
import nftDetailsLoader from "../../../assets/nft-details-loader.gif";
import { useAddress } from "@thirdweb-dev/react";

import "./nft-media.css";
import { OpenseaViewNFTTokenURL } from "../../../constants/common-constants";

function NftMedia({ index, height, width, live, nftDetails, contractAddress, nftData }) {
  const [tokenId, setTokenId] = useState(-1);
  const [loaded, setLoaded] = useState(nftDetails && nftDetails["media"] == "" ? true : false);
  const [reduceImageOpacity, setReduceImageOpacity] = useState(false);

  const address = useAddress();

  const handleLoad = () => {
    setLoaded(true);
  };

  if (nftDetails && nftDetails["tokenId"]) {
    if (nftDetails && nftDetails["tokenId"] != -1 && nftDetails["tokenId"] != tokenId) {
      setTokenId(nftDetails["tokenId"]);
    }
  }

  const openNftDetails = (contractAddress, tokenId) => {
    let url = `${OpenseaViewNFTTokenURL}/${contractAddress}/${tokenId}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  useEffect(() => {
    if (address) {
      if (
        nftData["live"] &&
        nftData["nft_mint_transaction"] &&
        nftData["nft_mint_transaction"].length &&
        nftData["nft_mint_transaction"][0] == "limited_edition_raffle"
      ) {
        if (nftData["allowlist"] && nftData["allowlist"].length && nftData["live"]) {
          if (!nftData["allowlist"].includes(address.toLowerCase())) {
            setReduceImageOpacity(true);
          }
        }
      }
    }
  }, [address]);

  return (
    <div
      id={`maybe-vid-${index}-container`}
      style={{
        backgroundImage:
          nftDetails && nftDetails["media"] == ""
            ? `url(${nftDetailsLoader})`
            : nftDetails["mediaType"] == "image"
            ? `url(${nftDetails["media"]})`
            : "",
        // backgroundImage: `url(${asset})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: nftDetails && nftDetails["media"] == "" ? "cover" : "contain",
        width: "100%",
        height: "100%",
        filter: loaded ? "none" : "blur(20px)",
        transition: "filter 0.6s",
        opacity: reduceImageOpacity ? "0.5" : "1",
      }}
      onClick={() => {
        if (tokenId != -1) {
          openNftDetails(contractAddress, nftDetails["tokenId"]);
        }
      }}
    >
      {nftDetails && nftDetails["media"] && nftDetails["mediaType"] == "image" ? (
        <img src={nftDetails["media"]} alt="" onLoad={handleLoad} style={{ display: "none" }} />
      ) : (
        ""
      )}

      {nftDetails && nftDetails["mediaType"] == "video" && nftDetails["media"] != "" ? (
        <center>
          <video
            key={nftDetails["media"]}
            id={`maybe-vid-${index}`}
            autoPlay={true}
            loop={true}
            muted
            controls={tokenId == -1}
            style={{ width: width }}
            onLoadedData={() => {
              try {
                setLoaded(true);
                let el = document.getElementById(`maybe-vid-${index}-container`);
                let vel = document.getElementById(`maybe-vid-${index}`);
                let h = getComputedStyle(el).height;
                vel.style.height = h;
              } catch (e) {
                console.log("Error: ", e);
              }
            }}
          >
            <source src={nftDetails["media"]} type="video/mp4" />
          </video>
        </center>
      ) : (
        ""
      )}
    </div>
  );
}

export default NftMedia;
