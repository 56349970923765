import { useState, useEffect } from "react";
import { useAddress } from "@thirdweb-dev/react";
import { MetaMaskWallet } from "@thirdweb-dev/react";
import { ThirdWebClientID } from "../constants/common-constants";

export function useWalletAddress(manualRefresh) {
  const otherAddress = useAddress();
  const [address, setAddress] = useState(undefined);
  const metamaskWallet = new MetaMaskWallet({
    clientId: ThirdWebClientID,
  });

  useEffect(() => {
    // console.log("useWalletAddress.js useEffect", address, otherAddress, manualRefresh);
    // don't run effect on initial state.
    if (address !== undefined && address.length == 42) {
      return;
    } else if (otherAddress && otherAddress.length == 42) {
      setAddress(otherAddress);
    } else {
      // Check if wallet is already connected
      metamaskWallet
        .getAddress()
        .then((wallet_add) => {
          // console.log(".....wallet_add", wallet_add);
          if (wallet_add && wallet_add.length == 42) {
            setAddress(wallet_add);
          }
        })
        .catch((e) => {
          // address not found, means wallet not connected.
          setAddress(false);
        });
    }
  }, [address, otherAddress, manualRefresh]);

  return address;
}
