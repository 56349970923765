import "./launch-info.css";

function LaunchInfo({ aboutArt }) {
  return (
    <div className="launch-info-container">
      {/* <div className="launch-info-header">
        <div className="launch-info-header-text">ABOUT ART</div>
      </div> */}
      <div className="launch-info-details">
        <div className="launch-info-details1-container">
          <div className="launch-info-details-message1-text">{aboutArt[0]}</div>
        </div>
        <div className="launch-info-details1-2-container">
          <div className="launch-info-details2-container">
            <div className="launch-info-details-message2-text">{aboutArt[1]}</div>
          </div>
          <div className="launch-info-details3-container">
            <div className="launch-info-details-message3-text">{aboutArt[2]}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LaunchInfo;
