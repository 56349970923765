import { useState, useEffect } from "react";
import WizardAPI from "../../wizard-api";
import "./step2.css";
import StepLoader from "../../../common-components/step-loader/step-loader";
import ThirdWebLogo from "../../../common-components/thirdweb-logo/thirdweb-logo";
import * as amplitude from "@amplitude/analytics-browser";

import { useAddress, useConnect } from "@thirdweb-dev/react";
import { metamaskWallet, coinbaseWallet, trustWallet, rainbowWallet, walletConnect } from "@thirdweb-dev/react";
import { ThirdWebChain, CoinbaseDeepLinkURL } from "../../../../../constants/common-constants";

const walletConnectConfig = walletConnect();
const coinbaseConfig = coinbaseWallet();
const metamaskConfig = metamaskWallet();
const trustWalletConfig = trustWallet();
const rainbowWalletConfig = rainbowWallet();

function LaunchStep2({ artistId, membershipId, stepChange, disableAllowlist }) {
  const connect = useConnect();
  const address = useAddress();
  const [loading, setLoading] = useState(false);
  const [walletConnectButtonClicked, setWalletConnectButtonClicked] = useState(false);
  const [isMetamaskBrowser, setIsMetamaskBrowser] = useState(false);
  const [connectedWallet, setConnectedWallet] = useState("");

  const getMemberDetails = async () => {
    setLoading(true);
    const getMemberRes = await WizardAPI.getMemberDetails(artistId, membershipId, address, "");
    if (getMemberRes["success"] && getMemberRes["code"] === 200) {
      stepChange(3, {
        connectedWallet: connectedWallet,
      });
      setLoading(false);
    } else {
      alert("Something went wrong.");
      stepChange(1);
    }
    try {
      amplitude.track("Wallet Connected", {
        wallet_connected: address ? "True" : "False",
        minting: "Pass",
        artist_id: artistId,
        membership_id: membershipId,
      });
    } catch (e) {}
  };

  const encodeURL = (url) => {
    const urlParts = url.split(/(\?|&|=)/);
    const encodedParts = urlParts.map((part) => (part.match(/(\?|&|=)/) ? part : encodeURIComponent(part)));
    return encodedParts.join("");
  };

  const handleConnect = async (wallet) => {
    // Trigger Amplitude Event
    try {
      amplitude.track("Select-Wallet Button Clicked", {
        wallet_connected: address ? "True" : "False",
        minting: "Pass",
        artist_id: artistId,
        membership_id: membershipId,
        button: wallet,
      });
    } catch (e) {}
    setWalletConnectButtonClicked(true);
    try {
      let w;
      if (wallet === "Metamask") {
        if (isMetamaskBrowser) {
          w = await connect(metamaskConfig, ThirdWebChain);
        } else {
          let websiteURL = window.location.href.split("//")[1];
          let metamaskDeepLinkURL = "https://metamask.app.link/dapp/";
          let metamaskBrowserURL = `${metamaskDeepLinkURL}${websiteURL}`;
          window.open(metamaskBrowserURL, "_self");
          stepChange(1);
        }
      } else if (wallet === "Coinbase") {
        w = await connect(coinbaseConfig, ThirdWebChain);
      } else if (wallet === "Rainbow") {
        w = await connect(rainbowWalletConfig, ThirdWebChain);
      } else if (wallet === "Trust-Wallet") {
        w = await connect(trustWalletConfig, ThirdWebChain);
      } else if (wallet === "Wallet-Connect") {
        w = await connect(walletConnectConfig, ThirdWebChain);
      }
      setConnectedWallet(wallet);
      setWalletConnectButtonClicked(false);
    } catch (error) {
      // console.log("error >>>>>>>>>>", error);
      setWalletConnectButtonClicked(false);
    }
  };

  useEffect(() => {
    if (disableAllowlist) {
      stepChange(1);
    }
    if (address) {
      getMemberDetails();
    }
    // Trigger Amplitude Event
    try {
      amplitude.track("Select-Wallet Page Viewed", {
        wallet_connected: address ? "True" : "False",
        minting: "Pass",
        artist_id: artistId,
        membership_id: membershipId,
      });
    } catch (e) {}
  }, [address]);

  useEffect(() => {
    let browser = String(window.navigator.userAgent).toLowerCase();
    if (browser.indexOf("webview metamaskmobile") != -1) {
      setIsMetamaskBrowser(true);
    }
  }, []);

  return (
    <>
      {loading ? (
        <StepLoader />
      ) : (
        <div className="mobile-launch-step3-container">
          <div className="mobile-connect-wallet-container">
            {walletConnectButtonClicked ? (
              <div className="mobile-launch-wallet-connecting-container">
                <div className="mobile-launch-wallet-connecting-text">
                  Please complete your wallet signin. Signing helps us know that you are the owner of the wallet
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="mobile-launch-connect-wallet-text-container">
              <div className="mobile-launch-connect-wallet-text">Choose your wallet</div>
            </div>
            <div className="mobile-launch-connect-wallet-option-container" onClick={() => handleConnect("Metamask")}>
              <img
                className="mobile-connect-wallet-option-image"
                src={require("../../../../../assets/metamask.webp")}
                alt="metamask-logo"
              />
            </div>
            <div className="mobile-launch-connect-wallet-option-container" onClick={() => handleConnect("Coinbase")}>
              <img
                className="mobile-connect-wallet-option-image"
                src={require("../../../../../assets/coinbase.webp")}
                alt="coinbase-logo"
              />
            </div>
            <div
              className="mobile-launch-connect-wallet-option-container"
              onClick={() => handleConnect("Wallet-Connect")}
            >
              <img
                className="mobile-connect-wallet-option-image"
                src={require("../../../../../assets/wallet-connect.webp")}
                alt="wallet-connect-logo"
              />
            </div>
            <div className="mobile-launch-step3-thirdweb-logo-container">
              <ThirdWebLogo width={"130px"} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default LaunchStep2;
