import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import "./header.css";
import { useDisconnect, useAddress } from "@thirdweb-dev/react";
import { useEffect, useState } from "react";
import { useWalletAddress } from "../../../../hooks/useWalletAddress";

function Header({ name, desc, twitter, discord, linktree, logo, disableAllowlist }) {
  const address = useAddress();
  const navigate = useNavigate();
  const disconnect = useDisconnect();

  const params = useParams();
  let [searchParams] = useSearchParams();
  const phase = searchParams.get("phase");
  const step = searchParams.get("step");
  let inviteCode = searchParams.get("invite_code");
  // console.log("phase and step from header", phase, step, inviteCode);

  const [displayAddress, setDisplayAddress] = useState("");
  const [phaseName, setPhaseName] = useState("");
  const [phaseSwitchLinkText, setPhaseSwitchLinkText] = useState("");

  const getNavigationUrlParams = (phase, step) => {
    let urlParams = "";
    if (inviteCode) {
      urlParams = `?invite_code=${inviteCode}` + "&phase=" + String(phase) + "&step=" + String(step);
    } else {
      urlParams = "?phase=" + String(phase) + "&step=" + String(step);
    }

    return urlParams;
  };

  const switchPhase = () => {
    if (phaseName == "post_launch") {
      localStorage.setItem("PhaseName", "launch");
      localStorage.setItem("PhaseStep", "1");
      window.location.reload();
    } else if (phaseName == "launch") {
      localStorage.setItem("PhaseName", "post_launch");
      localStorage.setItem("PhaseStep", "1");
      window.location.reload();
    } else {
      // do nothing
    }
  };

  const connectWallet = () => {
    if (disableAllowlist) return;
    if (address) {
      disconnect();
      localStorage.clear();
    } else {
      let connectWalletStep = 3;
      if (phase === "launch" || phase == "post_launch") {
        connectWalletStep = 2;
      }
      navigate({
        pathname: "/mobile/" + String(params.membershipSlug),
        search: getNavigationUrlParams(phase, connectWalletStep),
      });
    }
  };

  const goToStep1 = () => {
    navigate({
      pathname: "/mobile/" + String(params.membershipSlug),
      search: getNavigationUrlParams(phase, 1),
    });
  };

  useEffect(() => {
    // console.log("address from the header>>>>>>>>>", address);
    if (address) {
      setDisplayAddress(address.substring(0, 3) + "..." + address.substring(address.length - 3));
    }
  }, [address]);

  // --
  useEffect(() => {
    // console.log("In useEffect");
    let p = localStorage.getItem("PhaseName");
    if (!!p && p != phaseName) {
      setPhaseName(p);
      // console.log("In useEffect: Setting phaseName", p);
    }
  }, []);

  useEffect(() => {
    if (phaseName == "post_launch") {
      setPhaseSwitchLinkText("Mint more");
    } else if (phaseName == "launch") {
      setPhaseSwitchLinkText("View my NFTs");
    } else {
      setPhaseSwitchLinkText("");
    }
  }, [phaseName]);

  return (
    <div className="mobile-header-container">
      <div className="mobile-name-desc-container">
        <div className="mobile-artist-logo-container">
          {logo ? <img className="mobile-artist-logo" src={logo} onClick={goToStep1} alt="artist-logo" /> : ""}
        </div>

        <div className="mobile-artist-name" onClick={goToStep1}>
          {name}
        </div>
      </div>

      <div className="mobile-artist-connect-container">
        <div
          className="mobile-wallet-connect"
          onClick={connectWallet}
          style={{
            backgroundColor: address ? "rgba(45, 183, 206, 0.1)" : "",
          }}
        >
          <img
            style={{ height: "15px", width: "15px" }}
            src={require("../../../../assets/mobile-wallet.webp")}
            alt="wallet"
          />
        </div>
      </div>
    </div>
  );
}

export default Header;
