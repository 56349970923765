import { useState, useEffect } from "react";
import { useAddress } from "@thirdweb-dev/react";
import { useContract, useOwnedNFTs } from "@thirdweb-dev/react";
import { FindNftOwned } from "../Step1/step1";

import "./step5.css";
import WizardAPI from "../../wizard-api";
import { OpenseaViewNFTTokenURL } from "../../../../constants/common-constants";

function PostLaunchStep5({ artistId, membershipId, stepChange, nftDetails, setUserOwnedNftDetails }) {
  const _HexValues = ["4", "5", "6", "7", "8", "9", "A", "B", "C", "D", "E"];
  const [mintingLive, setMintingLive] = useState(false);
  const [mintLiveDateOn, setMintLiveDateOn] = useState({});
  const [mintEndCountdown, setMintEndCountdown] = useState({});
  const [userOwnLaunchNFT, setUserOwnLaunchNFT] = useState(false);
  const [collectors, setCollectors] = useState({});

  const [email, setEmail] = useState("");
  const [removeNotification, setRemoveNotification] = useState(true);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [showButtonLoader, setShowButtonLoader] = useState(false);
  const [error, setError] = useState(false);

  const address = useAddress();

  let launchNftTokenId = nftDetails[nftDetails.length - 1].token_id;
  let launchNftContractAddress = nftDetails[nftDetails.length - 1].contract_address;
  let userHasPassNFT = false;
  const [allNftDataSet, setAllNftDataSet] = useState(false);

  let userOwnedNftDetails = [];

  let userOwnedNftDataLoading;
  let userOwnedNftData;
  let userOwnedNftDataOwnedError;

  let nftOwnedData = new Array(nftDetails.length);

  for (let i = 1; i < nftDetails.length; i++) {
    if (nftDetails[i].mint_url) {
      continue;
    }
    nftOwnedData[i] = FindNftOwned(nftDetails[i].contract_address, address);

    if (nftDetails[i].contract_address == launchNftContractAddress) {
      userOwnedNftData = nftOwnedData[i][0];
      userOwnedNftDataLoading = nftOwnedData[i][1];
      userOwnedNftDataOwnedError = nftOwnedData[i][2];
    }
  }

  const onValueChange = (evt) => {
    if (showButtonLoader) return;
    setEmail(evt.target.value);
  };

  const handleFocus = (e) => {
    if (showButtonLoader) return;
    setError(false);
    e.currentTarget.select();
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const storeEmailAddress = async () => {
    if (showButtonLoader) return;
    setShowButtonLoader(true);
    if (email === "") {
      setShowButtonLoader(false);
      return;
    }
    if (!validateEmail(email)) {
      setError(true);
      setShowButtonLoader(false);
      return;
    }
    if (!address) {
      stepChange(2);
    }
    const storeEmailRes = await WizardAPI.storeEmail(artistId, membershipId, address, email);
    if (storeEmailRes["success"] && storeEmailRes["code"] === 200) {
      setShowButtonLoader(false);
      setRemoveNotification(false);
      setNotificationMessage("Done! We'll send you email reminders closer to the date of mint.");
    } else {
      setShowButtonLoader(false);
    }
  };

  const downloadCalenderInvite = () => {
    if (showButtonLoader) return;
    window.location.href = nftDetails[0].calender_invite_url;
    setRemoveNotification(false);
    setNotificationMessage("Reminder downloaded! Import the invite into your calendar app to stay updated");
  };

  const findMintEndCountdownTime = () => {
    var mintEndDate = new Date(nftDetails[0].mint_end);
    mintEndDate = convertToLocalTimestamp(mintEndDate);
    const now = new Date();
    var sec_num = (mintEndDate - now) / 1000;
    var days = Math.floor(sec_num / (3600 * 24));
    var hours = Math.floor((sec_num - days * (3600 * 24)) / 3600);
    var minutes = Math.floor((sec_num - days * (3600 * 24) - hours * 3600) / 60);
    var seconds = Math.floor(sec_num - days * (3600 * 24) - hours * 3600 - minutes * 60);

    if (days < 10) {
      days = "0" + days;
    }
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    setMintEndCountdown({
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    });
  };

  const convertToLocalTimestamp = (timestamp) => {
    var preLaunchStartTime = timestamp + "Z";
    var datetime = new Date(preLaunchStartTime);
    return datetime;
  };

  const findCurrentPhase = () => {
    const mintStartTime = convertToLocalTimestamp(nftDetails[0].mint_start);
    const mintEndDate = convertToLocalTimestamp(nftDetails[0].mint_end);
    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    var now = new Date();
    var currentDateAndTime = now;

    // console.log("mint start time>>", mintStartTime);
    // console.log("mint end time>>", mintEndDate);
    // console.log("current time>>>>>>", currentDateAndTime);
    if (mintStartTime <= currentDateAndTime && currentDateAndTime < mintEndDate) {
      findMintEndCountdownTime();
      setMintingLive(true);
    } else {
      let d = new Date(mintStartTime);
      let time = new Intl.DateTimeFormat("default", {
        hour12: true,
        hour: "numeric",
        minute: "numeric",
      }).format(d);
      time = time.toUpperCase();

      let month = new Intl.DateTimeFormat("default", {
        month: "long",
      }).format(d);
      month = month.toUpperCase();

      setMintLiveDateOn({
        time: time,
        month: month,
        date: d.getDate(),
        year: d.getFullYear(),
        day: weekday[d.getDay()].toUpperCase(),
      });
      console.log("minting will live on>>>", time, d.getMonth() + 1, d.getDate(), d.getFullYear());
      setMintingLive(false);
    }
  };

  const fetchCollectorsDetails = async () => {
    const collectorsDetailsRes = await WizardAPI.getCollectorDetails(1, 1, nftDetails[0].drop_number);
    if (collectorsDetailsRes["success"] && collectorsDetailsRes["code"] === 200) {
      const data = collectorsDetailsRes["data"];
      const collectorList = data && data.length ? data[0]["collectors"] : [];
      setCollectors(collectorList);
    }
  };

  const getGradient = () => {
    let color1 = "#";
    for (let i = 0; i < 6; i++) {
      let x = Math.round(Math.random() * (_HexValues.length - 1));
      let y = _HexValues[x];
      color1 += y;
    }
    let color2 = "#";
    for (let i = 0; i < 6; i++) {
      let x = Math.round(Math.random() * (_HexValues.length - 1));
      let y = _HexValues[x];
      color2 += y;
    }
    // keep light color first
    if (color1 < color2) {
      let t = color1;
      color1 = color2;
      color2 = t;
    }
    let angle = Math.round(Math.random() * 100);
    return "linear-gradient(" + angle + "deg, " + color1 + ", " + color2 + ")";
  };

  // -------------------------------------------------
  // useEffects
  // -------------------------------------------------
  useEffect(() => {
    // console.log("collects updated");
    setTimeout(() => {
      try {
        // console.log("changing class...");
        let el0 = document.getElementById("collector-pfp-0");
        let el5 = document.getElementById("collector-pfp-5");
        el0.className = "launch-step5-collector-pfp launch-step5-collector-pfp-zero-size";
        el5.className = "launch-step5-collector-pfp launch-step5-collector-pfp-full-size";
      } catch (e) {
        //
      }
    }, 1300);
  }, [collectors]);

  useEffect(() => {
    //checking if the user has launch nft or not
    if (userOwnedNftData) {
      if (userOwnedNftData.length === 0) {
        setUserOwnLaunchNFT(false);
      } else {
        setUserOwnLaunchNFT(true);
      }
    }
  }, [userOwnedNftData]);

  useEffect(() => {
    let allDataLoaded = true;
    userOwnedNftDetails = [];

    // static big image/video as placeholder
    userOwnedNftDetails.push({
      media: nftDetails[0]["media"],
      media_type: nftDetails[0]["media_type"],
      contract_address: nftDetails[0].contract_address,
      token_id: nftDetails[0].token_id,
    });

    for (let i = 0; i < nftOwnedData.length; i++) {
      if (!nftOwnedData[i]) {
        continue;
      }
      let nftsOwned = nftOwnedData[i][0];
      let nftDataLoading = nftOwnedData[i][1];
      let nftDataError = nftOwnedData[i][2];
      if (nftDataLoading) {
        allDataLoaded = false;
      }
      if (nftDataLoading === false && !nftDataError) {
        for (let j = 0; j < nftsOwned.length; j++) {
          if (nftsOwned[j]["type"] === "ERC1155") {
            for (let k = 0; k < nftsOwned[j]["quantityOwned"]; k++) {
              let media = nftsOwned[j]["metadata"]["animation_url"];
              let media_type = !!media ? "video" : "image";
              media = media || nftsOwned[j]["metadata"]["image"];

              userOwnedNftDetails.push({
                media: media,
                media_type: media_type,
                contract_address: nftDetails[i].contract_address,
                token_id: nftsOwned[j]["metadata"]["id"],
              });
            }
          } else {
            userOwnedNftDetails.push({
              media: nftsOwned[j]["metadata"]["image"],
              media_type: "image",
              contract_address: nftDetails[i].contract_address,
              token_id: nftsOwned[j]["metadata"]["id"],
            });
          }
        }
      }
    }

    // checking if user has launch pass or not
    for (let i = 0; i < userOwnedNftDetails.length; i++) {
      if (userOwnedNftDetails[i]["contract_address"] == launchNftContractAddress) {
        if (!userHasPassNFT) {
          userHasPassNFT = true;
        }
      }
    }

    // if all the nft data is loaded then update the images of the owned nfts
    if (allDataLoaded) {
      if (!allNftDataSet && userHasPassNFT) {
        setUserOwnedNftDetails(userOwnedNftDetails, true);
        setAllNftDataSet(true);
      }
    }
  }, [nftOwnedData]);

  useEffect(() => {
    findCurrentPhase();
    findMintEndCountdownTime();
    fetchCollectorsDetails();
  }, []);

  return (
    <>
      <div className="post-launch-step5-container">
        {removeNotification ? (
          ""
        ) : (
          <>
            {/* title and desc of the nft */}
            <div className="post-launch-step5-notification-container">
              <div className="post-launch-step5-notification-text-container">{notificationMessage}</div>
              <img
                className="post-launch-step5-notification-remove-image"
                src={require("../../../../assets/remove.webp")}
                onClick={() => setRemoveNotification(true)}
                alt="cross"
              />
            </div>
          </>
        )}
        <div className="post-launch-step5-nft-details-container">
          <div className="post-launch-step5-nft-name-desc-container">
            <div className="post-launch-step5-nft-name-container">
              <div className="post-launch-step5-nft-name-text">{nftDetails[0].title}</div>
            </div>
            <div className="post-launch-step5-nft-desc-container">
              <div className="post-launch-step5-nft-desc-text">{nftDetails[0].description}</div>
            </div>
          </div>

          {/* minting not live */}
          <div className="post-launch-step5-nft-date-container">
            {/* <div className="post-launch-step5-not-live-mint-text-container">
              <div className="post-launch-step5-not-live-mint-text">Coming up next</div>
            </div> */}
            <div className="post-launch-step5-mint-more-container">
              <div className="post-launch-step5-mint-more-text-container">
                <div className="post-launch-step5-mint-more-text">You have access to X more mints</div>
              </div>
              <div
                className="post-launch-step5-mint-more-button-container"
                onClick={() => {
                  stepChange(1);
                  setTimeout(() => {
                    window.location.reload();
                  }, 800);
                }}
              >
                <div className="post-launch-step5-mint-more-button-text">Claim now</div>
              </div>
            </div>
            <div className="post-launch-step5-not-live-mint-date-container">
              <div className="post-launch-step5-not-live-day">
                <div className="post-launch-step5-not-live-day-text">{mintLiveDateOn.day}</div>
              </div>
              <div className="post-launch-step5-not-live-mint-date">
                <div className="post-launch-step5-not-live-mint-date-text">{mintLiveDateOn.date}</div>
              </div>
              <div className="post-launch-step5-not-live-month">
                <div className="post-launch-step5-not-live-month-text">{mintLiveDateOn.month}</div>
              </div>
            </div>
          </div>
        </div>

        {/* wallet is connected but nft balance of user is loading */}
        <div className="post-launch-step5-navigation-container">
          {address && userOwnedNftDataLoading ? (
            <div className="loading-container">
              <img
                className="step-loader-image"
                src="https://d1p8rb4zvg5uux.cloudfront.net/small-media/screen-loading.gif"
                alt="loader"
              />
            </div>
          ) : (
            ""
          )}

          {/* user does'nt own launch nft */}
          {address && !userOwnedNftDataLoading && !userOwnLaunchNFT ? (
            <div className="post-launch-step5-launch-nft-not-found-container">
              <div className="post-launch-step5-launch-nft-not-found-text-container">
                <div className="post-launch-step5-launch-nft-not-found-text">
                  Sorry, you don't own the reMember Pass
                </div>
              </div>
              <div className="post-launch-step5-button-container">
                <div
                  className="post-launch-step5-button"
                  onClick={() => {
                    // __CONFIG__
                    let url = `${OpenseaViewNFTTokenURL}/${launchNftContractAddress}/${launchNftTokenId}`;
                    window.open(url, "_blank", "noopener,noreferrer");
                  }}
                >
                  <div className="post-launch-step5-button-text">Buy on secondary</div>
                </div>
              </div>
              <div className="post-launch-step5-thirdweb-container">
                <img
                  className="post-launch-step5-thirdweb-logo"
                  src={require("../../../../assets/thirdweb-logo.webp")}
                  alt="thirdweb-logo"
                />
              </div>
            </div>
          ) : (
            ""
          )}

          {/* wallet connected, user has launch nft and minting is not live  */}
          {address && !userOwnedNftDataLoading && userOwnLaunchNFT && !mintingLive ? (
            <div className="post-launch-step5-has-launch-nft-minting-not-live-container">
              <input
                className="input-box"
                defaultValue={email}
                placeholder="Enter email address"
                onInputCapture={onValueChange}
                onFocus={handleFocus}
                disabled={showButtonLoader}
                autoFocus
              />
              <div className="post-launch-step5-has-launch-nft-minting-not-live-error-container">
                {error ? "Please enter a valid email address" : ""}
              </div>
              <div className="pre-launch-step4-main-button-container">
                <div style={{ width: "250px" }} className="pre-launch-step4-main-button" onClick={storeEmailAddress}>
                  <div className="pre-launch-step4-main-button-text">
                    {showButtonLoader ? (
                      <img
                        className="pre-launch-step4-button-loader"
                        src={require("../../../../assets/button-loader.gif")}
                        alt="button-loader"
                      />
                    ) : (
                      "Subscribe for reminders"
                    )}
                  </div>
                </div>
              </div>
              <div className="pre-launch-step4-decision-container">or</div>
              <div className="pre-launch-step4-secondary-button-container">
                <div className="pre-launch-step4-secondary-button-text" onClick={downloadCalenderInvite}>
                  Add to calendar
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {/* wallet is not connected */}
          {!address ? (
            <>
              <div className="post-launch-step5-button-container">
                <div className="post-launch-step5-button" onClick={() => stepChange(2)}>
                  <div className="post-launch-step5-button-text">Connect Wallet</div>
                </div>
              </div>
              <div className="post-launch-step5-thirdweb-container">
                <img
                  className="post-launch-step5-thirdweb-logo"
                  src={require("../../../../assets/thirdweb-logo.webp")}
                  alt="thirdweb-logo"
                />
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

export default PostLaunchStep5;
