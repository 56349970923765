import { useState, useEffect } from "react";
import WizardAPI from "../../wizard-api";
import StepLoader from "../../../common-components/step-loader/step-loader";
import StepButton from "../../../common-components/step-button/step-button";
import ThirdWebLogo from "../../../common-components/thirdweb-logo/thirdweb-logo";
import { useAddress } from "@thirdweb-dev/react";

import "./step2.css";

function PreLaunchStep2({ artistId, membershipId, stepChange, disableAllowlist }) {
  const [loading, setLoading] = useState(true);
  const [stepDetails, setStepDetails] = useState({});
  const address = useAddress();

  const fetchStepDetails = async () => {
    const stepDetailsRes = await WizardAPI.getStepDetails(artistId, membershipId, "pre_launch", 2);
    // console.log("pre-launch.js/step2.js step details>>>>>>", stepDetailsRes["data"]);
    if (stepDetailsRes["success"] && stepDetailsRes["code"] === 200) {
      setStepDetails(stepDetailsRes["data"]);
      setLoading(false);
    } else {
      alert("Something went wrong.");
      stepChange(1);
    }
  };
  useEffect(() => {
    if (disableAllowlist) {
      stepChange(1);
    }
    fetchStepDetails();
  }, []);
  return (
    <>
      {loading ? (
        <StepLoader />
      ) : (
        <div className="mobile-pre-launch-step2-container">
          <div className="mobile-pre-launch-step2-desc-heading">{stepDetails["heading"]}</div>
          <div className="mobile-pre-launch-step2-desc-sub-heading">{stepDetails["sub-heading1"]}</div>
          <div className="mobile-pre-launch-step2-desc-sub-heading">{stepDetails["sub-heading2"]}</div>
          <div className="mobile-pre-launch-step2-navigation-container">
            <div onClick={() => stepChange(3)}>
              <StepButton buttonText={!address ? stepDetails["button-cta"] : "Next"} width="150px" height="45px" />
            </div>
            <ThirdWebLogo width="140px" />
          </div>
        </div>
      )}
    </>
  );
}

export default PreLaunchStep2;
