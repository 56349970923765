import { useState, useEffect } from "react";
import { useAddress } from "@thirdweb-dev/react";
import WizardAPI from "../../wizard-api";
import "./step5.css";
import StepLoader from "../../../common-components/step-loader/step-loader";

function PreLaunchStep5({ artistId, membershipId, stepChange, launchReminderData, disableAllowlist }) {
  const address = useAddress();

  const [loading, setLoading] = useState(true);
  const [stepDetails, setStepDetails] = useState({});
  const [showEmailNotification, setShowEmailNotification] = useState(false);
  const [showCalenderNotification, setShowCalenderNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [removeNotification, setRemoveNotification] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const [referralCodeCopied, setReferralCodeCopied] = useState(false);
  const [emailCopied, setEmailCopied] = useState(false);
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };

  const sendEmail = () => {
    const emailBody = encodeURIComponent(stepDetails["email-body"].replaceAll("REFERRAL_CODE", referralCode));
    window.open(`mailto:''?subject=An Invite for Moments&body=${emailBody}`);
  };

  const getTweet = () => {
    let tweet = stepDetails["email-body"].replaceAll("REFERRAL_CODE", referralCode);
    tweet = tweet.replaceAll("&", "and");
    // console.log("pre-launch/step5.js tweet>>>", tweet);
    window.open(`https://twitter.com/intent/tweet?text=${tweet}`, "_blank");
  };

  const copyToClipboard = (type) => {
    if (type === "code") {
      setReferralCodeCopied(true);
      navigator.clipboard.writeText(referralCode);
    } else {
      navigator.clipboard.writeText(stepDetails["email-body"].replaceAll("REFERRAL_CODE", referralCode));
      setEmailCopied(true);
    }
  };

  const setEmailNotification = () => {
    setShowEmailNotification(true);
    setNotificationMessage("Done! We'll send you email reminders closer to the date of mint.");
  };

  const setCalenderInviteNotification = () => {
    setShowCalenderNotification(true);
    setNotificationMessage("Reminder downloaded! Import the calendar invite into your app to stay updated.");
  };

  const fetchStepDetails = async () => {
    const stepDetailsRes = await WizardAPI.getStepDetails(artistId, membershipId, "pre_launch", 4);
    // console.log("pre-launch.js/step5.js stepDetails>>>>>>", stepDetailsRes["data"]);

    if (stepDetailsRes["success"] && stepDetailsRes["code"] === 200) {
      setStepDetails(stepDetailsRes["data"]);
      const getMemberRes = await WizardAPI.getMemberDetails(artistId, membershipId, address, "");
      if (getMemberRes["success"] && getMemberRes["code"] === 200) {
        // console.log("pre-launch.js/step5.js memberDetails>>>>>>>>", getMemberRes);

        if (!getMemberRes["data"]["on_allowlist"]) {
          // if the user is not on the allowlist then send user to step4
          stepChange(4);
        }

        if (getMemberRes["data"]["referral_code"]) {
          setReferralCode(getMemberRes["data"]["referral_code"]);
        }

        if (!launchReminderData["calenderInvite"] && !launchReminderData["emailInvite"]) {
          // if the no data from the previous step
          if (getMemberRes["data"]["email"]) {
            setEmailNotification();
          } else {
            setCalenderInviteNotification();
          }
        } else if (launchReminderData["emailInvite"]) {
          // if the user had given email in the previous step then show email notification
          setEmailNotification();
        } else if (launchReminderData["calenderInvite"]) {
          // if user had downloaded the calender invite then show the invite notification
          setCalenderInviteNotification();
        }
        setLoading(false);
      } else {
        alert("Something went wrong.");
        stepChange(1);
      }
    } else {
      alert("Something went wrong.");
      stepChange(1);
    }
  };

  useEffect(() => {
    if (disableAllowlist) {
      // if the pre-launch is over then change step to 1
      stepChange(1);
    }

    if (address) {
      // if address is present then fetch step details
      fetchStepDetails();
    } else {
      // if address is not present then change step to connect wallet or wallet is disconnected
      stepChange(3);
    }
  }, [address]);

  return (
    <>
      {loading ? (
        <StepLoader />
      ) : (
        <div className="pre-launch-step5-container">
          {removeNotification ? (
            ""
          ) : (
            <div className="pre-launch-step5-notification-container">
              <div className="pre-launch-step5-notification-text-container">{notificationMessage}</div>
              <img
                className="pre-launch-step5-notification-remove-image"
                src={require("../../../../assets/remove.webp")}
                onClick={() => setRemoveNotification(true)}
                alt="cross"
              />
            </div>
          )}
          <div className="pre-launch-step5-heading-container">
            <div className="pre-launch-step5-heading">{stepDetails["main-heading"]}</div>
          </div>
          <div className="pre-launch-step5-sub-heading-container">
            <div className="pre-launch-step5-sub-heading-image-container">
              <img
                className="pre-launch-step5-sub-heading-image"
                src={require("../../../../assets/invite.webp")}
                alt="invite"
              />
            </div>
            <div className="pre-launch-step5-sub-heading-text-container">
              <div className="pre-launch-step5-sub-heading-text">Gift your friend an allowlist spot</div>
            </div>
          </div>
          <div className="pre-launch-step5-referral-container">
            <div className="pre-launch-step5-referral">
              <div className="pre-launch-step5-referral-text-image-container">
                <div className="pre-launch-step5-referral-image-container ">
                  <img
                    className="pre-launch-step5-referral-image"
                    src={require("../../../../assets/referral-image-1.webp")}
                    alt="invite"
                  />
                </div>
                <div className="pre-launch-step5-referral-text">{stepDetails["sub-heading-1"]}</div>
              </div>
              <div className="pre-launch-step5-referral-text-image-container">
                <div className="pre-launch-step5-referral-image-container ">
                  <img
                    className="pre-launch-step5-referral-image"
                    src={require("../../../../assets/referral-image-2.webp")}
                    alt="invite"
                  />
                </div>
                <div className="pre-launch-step5-referral-text">{stepDetails["sub-heading-2"]}</div>
              </div>
            </div>
            <div className="pre-launch-step5-code">
              <div className="pre-launch-step5-code-container">
                <div className="pre-launch-step5-referral-code" onMouseEnter={onHover} onMouseLeave={onLeave}>
                  {referralCode}
                </div>
                {hover && referralCodeCopied ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "70px",
                      height: "30px",
                      position: "absolute",
                      zIndex: 2,
                      marginTop: "85px",
                      marginRight: "40px",
                      backgroundColor: "grey",
                      color: "white",
                      borderRadius: "5px",
                      fontFamily: "poppins",
                      fontSize: "12px",
                    }}
                  >
                    Copied.
                  </div>
                ) : (
                  ""
                )}
                <img
                  onClick={() => copyToClipboard("code")}
                  className="pre-launch-step5-copy-image"
                  src={
                    referralCodeCopied
                      ? require("../../../../assets/text-copied.webp")
                      : require("../../../../assets/copy.webp")
                  }
                  alt="copy-to-clipboard"
                />
              </div>
            </div>
          </div>
          <div className="pre-launch-step5-share-container">
            <div className="pre-launch-step5-email-text-container">
              <div className="pre-launch-step5-email-text">
                {stepDetails["email-body"].replaceAll("REFERRAL_CODE", referralCode)}
              </div>
            </div>
            <div className="pre-launch-step5-button-container">
              <div className="pre-launch-step5-email-button-container" onClick={getTweet}>
                {stepDetails["button-cta"]}
              </div>
              <div className="pre-launch-step5-secondary-button-container">
                <img
                  className="pre-launch-step5-left-arrow"
                  src={require("../../../../assets/left-arrow.webp")}
                  alt="left-arrow"
                />
                <div className="pre-launch-step5-secondary-button-text " onClick={() => stepChange(1)}>
                  Back to home
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PreLaunchStep5;
