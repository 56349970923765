import { useState, useEffect } from "react";
import WizardAPI from "../../wizard-api";
import "./step3.css";
import StepLoader from "../../../common-components/step-loader/step-loader";
import { ThirdWebChain } from "../../../../constants/common-constants";

import { Ethereum } from "@thirdweb-dev/chains";
import { useConnect, useAddress } from "@thirdweb-dev/react";
import { metamaskWallet, coinbaseWallet, trustWallet, rainbowWallet, walletConnect } from "@thirdweb-dev/react";

const walletConnectConfig = walletConnect({ qrModal: "walletConnect" });
const coinbaseConfig = coinbaseWallet();
const metamaskConfig = metamaskWallet();
const trustWalletConfig = trustWallet();
const rainbowWalletConfig = rainbowWallet();

function PreLaunchStep3({ artistId, membershipId, stepChange, disableAllowlist }) {
  const connect = useConnect();
  const address = useAddress();
  const [loading, setLoading] = useState(false);
  const [walletConnectButtonClicked, setWalletConnectButtonClicked] = useState(false);

  const getMemberDetails = async () => {
    setLoading(true);
    const getMemberRes = await WizardAPI.getMemberDetails(artistId, membershipId, address, "");
    // console.log("pre-launch.js/step3.js member details>>>>>>", getMemberRes);
    if (getMemberRes["success"] && getMemberRes["code"] === 200) {
      if (getMemberRes["data"]["on_allowlist"]) {
        stepChange(4, getMemberRes["data"]);
      } else {
        stepChange(3.1);
      }

      setLoading(false);
    } else {
      alert("Something went wrong.");
      stepChange(1);
    }
  };

  const handleConnect = async (wallet) => {
    try {
      let w;
      setWalletConnectButtonClicked(true);
      if (wallet === "Metamask") {
        w = await connect(metamaskConfig, ThirdWebChain);
      } else if (wallet === "Coinbase") {
        w = await connect(coinbaseConfig, ThirdWebChain);
      } else if (wallet === "Rainbow") {
        w = await connect(rainbowWalletConfig, ThirdWebChain);
      } else if (wallet === "Trust-Wallet") {
        w = await connect(trustWalletConfig, ThirdWebChain);
      } else if (wallet === "Wallet-Connect") {
        w = await connect(walletConnectConfig, ThirdWebChain);
      }
    } catch (error) {
      setWalletConnectButtonClicked(false);
    }
  };

  useEffect(() => {
    if (disableAllowlist) {
      stepChange(1);
    }
    if (address) {
      getMemberDetails();
    }
  }, [address]);

  return (
    <>
      {loading ? (
        <StepLoader />
      ) : (
        <div className="pre-launch-step3-container">
          <div className="connect-wallet-container">
            {walletConnectButtonClicked ? (
              <div className="wallet-connecting-container">
                <div className="wallet-connecting-text">
                  Please complete your wallet signin. Signing helps us know that you are the owner of the wallet
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="connect-wallet-text-container">
              <div className="connect-wallet-text">Choose your wallet</div>
            </div>
            <div className="connect-wallet-option-container" onClick={() => handleConnect("Metamask")}>
              <img
                className="connect-wallet-option-image"
                src={require("../../../../assets/metamask.webp")}
                alt="metamask-logo"
              />
            </div>
            <div className="connect-wallet-option-container" onClick={() => handleConnect("Coinbase")}>
              <img
                className="connect-wallet-option-image"
                src={require("../../../../assets/coinbase.webp")}
                alt="coinbase-logo"
              />
            </div>
            {/* <div
              className="connect-wallet-option-container"
              onClick={() => handleConnect("Rainbow")}
            >
              <img
                className="connect-wallet-option-image"
                src={require("../../../../assets/rainbow.webp")}
              />
            </div>
            <div
              className="connect-wallet-option-container"
              onClick={() => handleConnect("Trust-Wallet")}
            >
              <img
                className="connect-wallet-option-image"
                src={require("../../../../assets/trust-wallet.webp")}
              />
            </div> */}
            <div className="connect-wallet-option-container" onClick={() => handleConnect("Wallet-Connect")}>
              <img
                className="connect-wallet-option-image"
                src={require("../../../../assets/wallet-connect.webp")}
                alt="wallet-connect-logo"
              />
            </div>

            <div className="connect-wallet-thirdweb-image-container">
              <img
                className="connect-wallet-thirdweb-image"
                src={require("../../../../assets/thirdweb-logo.webp")}
                alt="thirdweb-logo"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PreLaunchStep3;
