import { useState, useEffect } from "react";
import "./step4.2.css";

function LaunchMintFail({ artistId, membershipId, stepChange, transactionDetails }) {
  useEffect(() => {
    // console.log("launch/step4.2.js transaction details>>>>>>", transactionDetails);
  }, []);

  return (
    <div className="mobile-launch-mint-fail">
      <div className="mobile-launch-mint-fail-details-error-message-text">
        There was an error processing your transaction.
      </div>
      <div className="mobile-launch-mint-fail-details-message-text">Please drop us an email at hello@tasseo.xyz</div>
    </div>
  );
}

export default LaunchMintFail;
